import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Mfooter';
import Config from '../layout/Config';

class Terms extends Component {
      constructor(props) {
    super(props);
    this.state = {
      phone_insurance:[],
      gadget_page:[],
      document:""
      
    };
  }
 
 componentDidMount() {

 
  const headers = new Headers();
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'multipart/form-data');
 
const url1 = Config.url+'api/document';

  fetch(url1, { method: 'GET', headers })
  .then(function(response) {
      return response.json();
  })
    .then(jsonStr => {
    this.setState({ 
      document: jsonStr
     });
     if(this.state.document == null){
      this.state.document = null;
    }
 });
 console.log(this.state.document)

 }


render() {
  console.log(this.state.gadget_page);   


  
  return (
 <div>
    <Header />

  <div class="prd-page">
  <div class="abt-footer">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="abt_footer_in text-center">
                            <h2>Privacy Policy </h2>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="container">
   <div dangerouslySetInnerHTML={{__html: this.state.document.privacy_policy}}>
   </div>
   </div>
 
<Footer />
</div>
);
}
}
export default Terms;
