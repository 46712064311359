import React, {Component} from 'react';
import Config from '../layout/Config';

class dsverification extends Component {
   
    componentDidMount() {

        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');
        var apiUrl = Config.crm + 'cmgpaymentVerification';
        fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({unique_id:this.props.match.params.id}),
            headers
        })
            .then(res => res.json())
            .then(result => {
                window.location = result.redirection;
            }, (error) => {
                this.setState({ error });
            });
        }
    
    render() {

        return (
            <div>
                
            </div>
        );
    }
}
export default dsverification;
