import React, { Component } from 'react';

import logo from './logo.svg';
import './App.css';
import './css/Anim.css';
import './css/style.css';
import { BrowserRouter, Route, Switch, Link} from 'react-router-dom';



import Home from './pages/Home';
import Aboutus from './pages/Aboutus';
import Contact from './pages/Contact';
import Product from './pages/Product';
import Terms from './pages/Terms';
import Cookies from './pages/Cookies';
import Products from './pages/Products';
import Pgtest from './pages/Pgtest';
import Details from './pages/Details';
import Plan from './pages/Plan';
import Payment from './pages/Payment';
import Quote_success from './pages/Quote_success';
import Thankyoupage from './pages/Thankyoupage';
import Success from './pages/Success';
import PaymentData from './pages/PaymentData';
import Carousel from './pages/Carousel';
import Gadgets from './pages/Gadgets';
import dsverification from './pages/3dsverification';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class App extends Component {
    render() {
        return ( 

            < div>
             
                < Switch>
                    < Route path="/" exact  component={ Home } exact />
                    < Route path="/Aboutus" exact  component={ Aboutus } />
                    < Route path="/Privacy-Policy" exact  component={ Terms } />
                    < Route path="/Cookies" exact  component={ Cookies } />
                    < Route path="/Contact" exact  component={ Contact } />
                    < Route path="/Gadgets/:id" exact  component={ Product } />
                    < Route path="/quotes/Products" exact  component={ Products } />
                    < Route path="/Pgtest" exact  component={ Pgtest } />
                    < Route path="/quotes/Details" exact  component={ Details } />
                    < Route path="/quotes/Plan" exact  component={ Plan } />
                    < Route path="/quotes/Payment" exact  component={ Payment } />
                    < Route path="/quotes/Quote_success" exact  component={ Quote_success } />
                    < Route path="/quotes/Success" exact  component={ Success } />
                    < Route path="/quotes/Thankyoupage" exact  component={ Thankyoupage } />
                    < Route path="/PaymentData/:id/:trans_id/:status_code" exact  component={ PaymentData } />
                    < Route path="/Carousel" exact  component={ Carousel } />
                    < Route path="/Gadgets" exact  component={ Gadgets } />
                    < Route path="/3dsverification/:id" exact  component={ dsverification } />
                </Switch>
            </div>
       
        );
    }
}
export default App;