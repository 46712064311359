import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Mfooter';
import Config from '../layout/Config';
import {Helmet} from "react-helmet";

class Aboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
          phone_insurance:[],
          gadget_page:[],
          document:"",
          seo:'',
          seo2:'',
          
        };
      }
      componentDidMount() {

 
        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');
       
      const url1 = Config.url+'api/document';
      
        fetch(url1, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            document: jsonStr
           });
           if(this.state.document == null){
            this.state.document = null;
          }
       });
       console.log(this.state.document)
       const urls7 = Config.url+'api/seo';
        fetch(urls7, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            seo: jsonStr['aboutus'][0].title,
            seo2: jsonStr['aboutus'][0].description,

           });
          
 
           
       });
       }
    render() {
        return (
<div>
<Header />  
<Helmet>
     
                <meta charSet="utf-8" />
                <title>{this.state.seo}</title>
                <meta name="description" content={this.state.seo2} />
                <link rel="canonical" href={'www.covermygadget.com' + this.props.location.pathname} />
            </Helmet> 
<div id="aboutus">
    <section id="abotus_section">
        <div class="aboutus_sec">
            <div class="container1">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="abt-left">
                            <h1>About Us</h1>
                            <p dangerouslySetInnerHTML={{__html: this.state.document.aboutus1}}>
                            </p>
                            <a href="/Contact"><button>Contact Us</button></a>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 margin-dl">
                        <div class="abt-right">
                            <img src="images/img.png" class="img-fluid"/>
                        </div>
                         </div>
                </div>
            </div>
         </div>
    </section>
    <section id="heretohelp_sec">
        <div class="here-help">
           <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                        <div class="here-help-left">
                            <img src="images/img2.png" class="img-fluid"/>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                        <div class="here-help-right">
                            <h1>
                                Here to help
                            </h1>
                            <p dangerouslySetInnerHTML={{__html: this.state.document.aboutus2}}>
                            </p>
                        </div>
                    </div>
                </div>
           </div>
        </div>
    </section>
    <section id="abt-footer">
        <div class="abt-footer">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <dv class="abt_footer_in text-center">
                            <p>
                                    Cover My Gadgets, as with all of our products, is 
                                    regulated by the Financial Conduct Authority (FCA), 
                                    giving you assurance of a professional, high level of 
                                    service
                            </p>
                            <center>
                                    <a href="/PgDetails"><button>Get Insured</button></a>
                            </center>
                        </dv>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<Footer />
</div>

);
}
}
export default Aboutus;
