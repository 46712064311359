import React, { Component, useState  } from "react";
import Header from "../layout/Header";
import Sidebanner from "../layout/Sidebanner";

import Footer from "../layout/Footer";
import Config from "../layout/Config";
import SimpleReactValidator from "simple-react-validator";
import MetaTags from 'react-meta-tags';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Iframe from 'react-iframe';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

class Details extends Component {
  constructor() {
    super();
    this.state = {
      apps: "yes",
      app: "",
      titles: "yes",
      title: "Mr",
      fnames: "yes",
      fname: "",
      lnames: "yes",
      lname: "",
      posts: "yes",
      post: "",
      emails: "yes",
      email: "",
      teles: "yes",
      tele: "",
      loading: true,
      addresss: "yes",
      address: "",
      yourDetails: [],
      product: [],
      add: [],
      mode: "return",
      address1: "",
      quote_id: "",
      quote_id1: "",
      postcode: "",
      // password: "",
      api_response: "",
      cpmail: 'null',
      cpemail: 'null',
      cpsms: 'null',
      cpphone: 'null',
      dob:'',
      month: fromMonth,
      startDate:'',
      dateshow:'0',
      dateofbirth_err:'',
      modalOpen: false,
      modalOpen1: false,
      // startDate: useState(new Date()),
      // setStartDate: useState(new Date())
    };
    this.handleChange = this.handleChange.bind(this);
    this.changePostcode = this.changePostcode.bind(this);
    this.validator = new SimpleReactValidator();

  }
  getDate = (value) => {
    var today = new Date();
    let currentDate = value.target.value;
    console.log(value.target.value);
    var day = moment(currentDate, "YYYY-MM-DD");
    let convertDate = value.target.value;
    this.setState({convertDate});
    console.log(day.format('YYYY'));

    var age_now = today.getFullYear() - day.format('YYYY');
    console.log(age_now);
    if(age_now < 19){
      this.setState({
         dateofbirth_err: "The Age of customer should be minimum 18 years."
        });
      
    }else{
      this.setState({
        dateofbirth_err: ""
        });
    }
  }

  changePostcode() {
    this.setState({ postcode: "1", address2: "aa" });
  }
  handleSelectChange = event => {
    this.setState({ address: event.target.value });
  };

  handleChange(event) {
    const state = this.state;
    state[event.target.name] = event.target.value;
    this.setState(state);
    this.setState({
      title1: "",
      fname1: "",
      lname1: "",
      post1: "",
      address1: "",
      tele1: "",
      email1: "",
      // password1:''
    });
  }
  handleOpen = () =>{
    this.setState({modalOpen : true});
  }
  handleOpen1 = () =>{
      this.setState({modalOpen1 : true});
  } 
  handleClose1 = event =>{

      this.setState({modalOpen1 : false});
  }
  handleClose = event =>{

      this.setState({modalOpen : false});
  } 
  setmode = (event) => {
    var checkbox = event.target.checked;
    if (checkbox == true) {
      var value = event.target.value;
      if (value == "mail") {
        this.setState({
          cpmail: event.target.value
        });
      } else if (value == "email") {
        this.setState({
          cpemail: event.target.value
        });
      } else if (value == "phone") {
        this.setState({
          cpphone: event.target.value
        });
      } else if (value == "sms") {
        this.setState({
          cpsms: event.target.value
        });
      }
    } else {
      var value = event.target.name;
      if (value == "cpmail") {
        this.setState({
          cpmail: 'null'
        });
      } else if (value == "cpemail") {
        this.setState({
          cpemail: 'null'
        });
      } else if (value == "cpphone") {
        this.setState({
          cpphone: 'null'
        });
      } else if (value == "cpsms") {
        this.setState({
          cpsms: 'null'
        });
      }
    }
  }
  getpost = () => {
    var apiUrl = Config.url+'api/getaddressfrompo';
    const data = {
      post: this.state.post
    };
    const headers = new Headers();
    headers.set("Accept", "application/json");
    
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers
    };
    fetch(apiUrl, options)
      .then(res => res.json())
      .then(
        result => {
          result.length > 0 ? this.setState({ add: result ,address : result[0]}): this.setState({ post: '' });
          // console.log(result);
        },
        error => {
          this.setState({ error });
        }
      );
  };
  componentDidMount() {
    this.setState({
      title: (localStorage.getItem("hactitle") == 'null' || localStorage.getItem("hactitle") == null ? "Mr" :localStorage.getItem("hactitle")),
      fname: localStorage.getItem("hacfname"),
      lname: localStorage.getItem("haclname"),
      post: localStorage.getItem("hacpost"),
      email: localStorage.getItem("hacemail"),
      tele: localStorage.getItem("hactele"),
      address: localStorage.getItem("hacaddress"),
      add: [localStorage.getItem("hacaddress")],
      convertDate: localStorage.getItem("dob"),
      // convertDates: localStorage.getItem("startdateofpolicy"),
      // password: localStorage.getItem("password"),
      cpmail:(localStorage.getItem("hacpmail")  == null ? "null" : localStorage.getItem("hacpmail")),
      cpemail:(localStorage.getItem("hacpemail")  == null ? "null" : localStorage.getItem("hacpemail")),
      cpphone:(localStorage.getItem("hacpphone") == null ? "null" : localStorage.getItem("hacpphone")),
      cpsms: (localStorage.getItem("hacpsms")  == null ? "null" : localStorage.getItem("hacpsms"))
    });
  }
  btnCreateAddress = () => {
    var title = this.state.title;
    var fname = this.state.fname;
    var lname = this.state.lname;
    var tele = this.state.tele;
    var post = this.state.post;
    var address = this.state.address;
    var email = this.state.email;
    var dob = this.state.convertDate;
    console.log(dob);
    // var password = this.state.password;
    // var startdatepolicy = this.state.convertDates;
    var contactpreferencephone = this.state.cpphone;
    var contactpreferencemail = this.state.cpmail;
    var contactpreferenceemail = this.state.cpemail;
    var contactpreferencesms = this.state.cpsms;
    if (this.validator.allValid()) {
        var today = new Date();
        var splitdate = dob.split('-')[2];
        var age_now = today.getFullYear() - splitdate;
      if(age_now < 19){
        this.setState({
          dateofbirth_err: "The Age of customer should be minimum 18 years."
          });
        
      }else{
        this.setState({
          dateofbirth_err: ""
          });
        localStorage.setItem("hactitle", this.state.title);
        localStorage.setItem("hacfname", this.state.fname);
        localStorage.setItem("haclname", this.state.lname);
        localStorage.setItem("hacpost", this.state.post);
        localStorage.setItem("hacemail", this.state.email);
        localStorage.setItem("hactele", this.state.tele);
        localStorage.setItem("hacaddress", this.state.address);
        localStorage.setItem("hacpphone", this.state.cpphone);
        localStorage.setItem("hacpmail", this.state.cpmail);
        localStorage.setItem("hacpemail", this.state.cpemail);
        localStorage.setItem("hacpsms", this.state.cpsms);      
        localStorage.setItem("dob", this.state.convertDate);      
        // localStorage.setItem("startdateofpolicy", this.state.convertDates);      
        // localStorage.setItem("password", this.state.password);      
        var cphone = (localStorage.getItem("hacpphone") == 'null' ? "0" : "1");
        var cmail = (localStorage.getItem("hacpmail")  == 'null' ? "0" : "1");
        var cemail = (localStorage.getItem("hacpemail") == 'null' ? "0" : "1");
        var csms = (localStorage.getItem("hacpsms")  == 'null' ? "0" : "1");
        
        // console.log(dob.split('-')[2]);
        // console.log(today.getFullYear());
        
        const quote_data = {
          title: localStorage.getItem("hactitle"),
          fname: localStorage.getItem("hacfname"),
          lname: localStorage.getItem("haclname"),
          post: localStorage.getItem("hacpost"),
          email: localStorage.getItem("hacemail"),
          tele: localStorage.getItem("hactele"),
          address: localStorage.getItem("hacaddress"),
          quote_id1: localStorage.getItem("quote_id"),
          cpphone: cphone,
          cpmail: cmail,
          cpemail: cemail,
          cpsms: csms
        };
        const headers = new Headers();
        headers.set("Accept", "application/json");
        const options = {
          method: "POST",
          body: JSON.stringify(quote_data),
          headers
        };
        var apiUrl = Config.url + "api/addQuote";
        fetch(apiUrl, options)
          .then(function(response) {
            return response.json();
          })
          .then(
            jsonStr => {
              this.setState({ yourDetails: jsonStr });

              localStorage.setItem(
                "quote_id",
                this.state.yourDetails["quote_id"]
              );
              localStorage.setItem(
                "reference_no",
                this.state.yourDetails["quote_details"]["reference_no"]
              );

              //*****API*****//
              const customer_details = {
                title: localStorage.getItem("hactitle"),
                first_name: localStorage.getItem("hacfname"),
                last_name: localStorage.getItem("haclname"),
                address: localStorage.getItem("hacaddress"),
                post_code: localStorage.getItem("hacpost"),
                mobile_number: localStorage.getItem("hactele"),
                email_address: localStorage.getItem("hacemail"),
                cphone: cphone,
                cpmail: cmail,
                cpemail: cemail,
                cpsms: csms,
                source:'SCCMG',
                dob:dob,
                startdatepolicy:null,
                password : ''
              };
              // console.log(customer_details)
              const data = {
                reference_id: localStorage.getItem("reference_no"),
                customer_details: customer_details
              };

              const headers1 = new Headers();
              headers.set("Accept", "application/json");
              headers.set("Access-Control-Allow-Headers" , "Content-Type");
              headers.set("Content-Type", "multipart/form-data");
              headers.set("Access-Control-Allow-Origin", "*");

              const options1 = {
                method: "POST",
                // mode: 'no-cors',
                body: JSON.stringify(data),
                headers1
              };
              var crmstep1 = Config.crm + "processstep1";
              fetch(crmstep1, options1)
                .then(function(response) {
                  return response.json();
                })
                .then(
                  jsonStr => {
                    this.setState({ api_response: jsonStr });
                    // console.log(this.state.api_response);
                    window.location = "/quotes/Products";
                  },
                  error => {
                    this.setState({ error });
                  }
                );
              //end of API
              // window.location = "/Products";
              // this.props.history.push('/Products');
              if (this.state.api_response) {
              }
            },
            error => {
              this.setState({ error });
            }
          );
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time you can use the autoForceUpdate
      // option to do this automatically`
      this.forceUpdate();
    }
  };
 
  
  render() {
    const { classes } = this.props;
    const { add,currentDate,convertDate} = this.state;
    const styles = {
      post_hid: {
        display: "none"
      }
    };
    
    return (
      
      <div>
        <Header />
          <MetaTags>
            <title>Gadgets Quote</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
        <section id="pg-yourdt">
          <div  className="container" >
            <div class="row">
              {/* <div class="col-md-4 col-lg-4">
                <Sidebanner />
              </div> */}
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="gen-your-quote">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h3>Generate your Quote</h3>
                      <p>
                        With just a few simple steps you will receive a
                        personalised quote
                      </p>
                    </div>
                  </div>
                  <div class="pg-details-step">
                    <div class="row justify-content-lg-around">
                      <div class="col-md-12 col-lg-2 pd-steps active mbactive a_change">
                        <span class="activespan">1</span>
                        Your Details
                        <i class="fas fa-angle-right"></i>
                      </div>
                      <div class="col-md-12 col-lg-2 pd-steps  a_change">
                        <span>2</span>
                        Product Details
                        <i class="fas fa-angle-right"></i>
                      </div>
                      <div class="col-md-12 col-lg-2 pd-steps a_change">
                        <span>3</span>
                        Product Review
                        <i class="fas fa-angle-right"></i>
                      </div>
                      <div class="col-md-12 col-lg-2 pd-steps a_change">
                        <span>4</span>
                        Payment Methods
                      </div>
                      <div class="col-md-12 col-lg-2 pd-steps a_change">
                        <span>5</span> Policy Summary
                      </div>
                    </div>
                  </div>
                  <div class="pl-dt-frm-sec">
                    <form class="pl-dt-form">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                          <FormControl variant="outlined" className="form_control">
                            <InputLabel htmlFor="outlined-title-native-simple">Title</InputLabel>
                            <Select
                              native
                              value={this.state.title}
                              onChange={this.handleChange}
                              label="title"
                              name="title"
                              className="form_control"
                            >
                                <option value="Mr" selected>Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Sir">Sir</option>
                                <option value="Lady">Lady</option>
                                <option value="Lord">Lord</option>
                                <option value="Dr">Dr</option>
                                <option value="Prof">Prof</option>
                                <option value="Rev">Rev</option>
                            </Select>
                          </FormControl>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <TextField label="Firstname"
                              name="fname"
                              value={this.state.fname}
                              onChange={this.handleChange}
                              variant="outlined"  
                              className="form_control"                           
                              />
                            <span class="fm_er" id="name_er"></span>
                            {this.validator.message(
                              "First name",
                              this.state.fname,
                              "required|alpha"
                            )}
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <TextField label="Lastname"
                            name="lname"
                            value={this.state.lname}
                            onChange={this.handleChange}
                            className="form_control"
                            variant="outlined" />
                            <span class="fm_er" id="lname_er"></span>
                            {this.validator.message(
                              "Last name",
                              this.state.lname,
                              "required|alpha"
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <div id="post_searchs" class="post_searchs">
                              <TextField label="Postcode"
                              value={this.state.post}
                              name="post"
                              onChange={this.handleChange}
                              className="form_control"
                              defaultValue="Postcode"
                              variant="outlined" />
                              <button
                                type="button"
                                class="post-find"
                                id="post_sc"
                                onClick={this.getpost}
                              >
                                Find
                              </button>
                              <span class="fm_er" id="post_er"></span>
                                {this.validator.message(
                                  "Postcode",
                                  this.state.post,
                                  "required"
                                )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <FormControl variant="outlined" className="form_control">
                              <InputLabel htmlFor="outlined-address-native-simple">Address</InputLabel>
                              {this.state.add && (
                              <Select
                                native
                                value={this.state.address}
                                name="address"
                                onChange={this.handleChange}
                                label="address"
                              >
                                <option aria-label="Choose your address" value="" />
                                {add.map((item, key) => <option key={key} value={item}>{item}</option>)}
                              </Select>
                              )}
                              {this.validator.message(
                                    "Address",
                                    this.state.address,
                                    "required"
                                  )}
                            </FormControl>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                          <TextField label="Phone"
                          value={this.state.tele}
                          name="tele"
                          onChange={this.handleChange}
                          className="form_control"
                          variant="outlined" />
                            <span class="fm_er" id="phone_er"></span>
                            {this.validator.message(
                              "Phone Number",
                              this.state.tele,
                              "required|numeric|min:10|max:15"
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                          <TextField label="Email"
                          value={this.state.email}
                          name="email"
                          onChange={this.handleChange}
                          className="form_control"
                          variant="outlined" />
                            <span class="fm_er" id="mail_er"></span>
                            {this.validator.message(
                              "email",
                              this.state.email,
                              "required|email"
                            )}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <TextField
                            variant="outlined"
                            label="Date of Birth"
                            type="date"
                            className="form_control"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={convertDate}
                            onChange={(value)=>this.getDate(value)}
                            />
                            <span class="fm_er" id="dob_er">{this.state.dateofbirth_err}</span>
                            {this.validator.message(
                              "date of birth",
                              convertDate,
                              "required"
                            )}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="title mt-5">
                            <h5>Contact Preferences</h5>
                          </div>
                          <p>
                            We may occasionly offer you products and services
                            which we feel may be of interest / are suitable to
                            you. If you consent to us providing this information,
                            please complete the consent below. You can opt out, at
                            any time, by visiting our &nbsp;
                            <a
                              target="_blank"
                              class="cvrclr"
                              href={Config.siteurl + "/contact-us/"} 
                            >
                            Contact page 
                            </a>
                            &nbsp;or by calling us.
                          </p>
                          <div class="form-group">
                            <div class="">
                              <p>I agree to be contacted via</p>
                              <div class="cntctprcheck">
                              
                                <label class="container-check">
                                <Checkbox
                                    checked={this.state.cpmail == 'null' ? "" : "checked"}
                                    onChange={this.setmode}
                                    name="cpmail"
                                    value="mail"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                  Mail
                                  
                                 
                                </label>
                                <label class="container-check">
                                <Checkbox
                                    checked={this.state.cpemail == 'null' ? "" : "checked"}
                                    onChange={this.setmode}
                                    name="cpemail"
                                    value="email"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                  Email
                                  
                                </label>
                                <label class="container-check">
                                <Checkbox
                                    checked={this.state.cpphone == 'null' ? "" : "checked"}
                                    onChange={this.setmode}
                                    name="cpphone"
                                    value="phone"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                  Phone
                                
                                </label>
                                <label class="container-check">
                                  <Checkbox
                                    checked={this.state.cpsms == 'null' ? "" : "checked"}
                                    onChange={this.setmode}
                                    name="cpsms"
                                    value="sms"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                  SMS
                                
                                </label>
                              </div>
                            
                            </div>
                          </div>
                          <div class="form-group documents">
                            <p><a target="_blank" href={Config.siteurl + "/privacy-policy"}>Privacy policy</a></p>
                          </div> 

                          <Modal
                            open={this.state.modalOpen}
                            onClose={this.handleClose}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            style={{background:'rgba(0,0,0,.8)', padding:'30px 0', display:'flex',alignItems:'center',justifyContent:'center'}} 
                            >
                                <React.Fragment >
                                    <Iframe width="80%"  height="100%" src={Config.siteurl + "/wp-content/uploads/2021/06/SC-Gadget-29_pw.pdf"}/>
                                    <button type="button" className="close_ico" onClick={this.handleClose}></button>
                                </React.Fragment>
                            </Modal>
                            <Modal
                            open={this.state.modalOpen1}
                            onClose={this.handleClose1}
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            style={{background:'rgba(0,0,0,.8)', padding:'30px 0', display:'flex',alignItems:'center',justifyContent:'center'}} 
                            >
                                <React.Fragment >
                                    <Iframe width="80%"  height="100%" src={Config.siteurl +"/wp-content/uploads/2021/06/SC-IPID-29_kf_.pdf"}/>
                                    <button type="button" className="close_ico" onClick={this.handleClose1}></button>
                                </React.Fragment>
                            </Modal>
                            <div class="documents">
                                <a className="mr-4"
                                    onClick={()=>this.handleOpen()}
                                    >
                                    <span><img src="../images/pdf.png"/></span>Terms & Conditions</a>
                                <a
                                    onClick={()=>this.handleOpen1()}
                                    >
                                    <span><img src="../images/ipid.png"/></span>IPID</a>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="prd-frm-sec input_field"></div>
                  <div class="bottom-btns text-right">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      
                        &nbsp;&nbsp;
                        {/* <Button variant="contained" color="primary" size="large" onClick={this.btnCreateAddress}>
                          Next
                        </Button> */}
                        <button class="nxt_btn" onClick={this.btnCreateAddress}>
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
export default Details;
// export default withStyles(styles, { withTheme: true })(Details);