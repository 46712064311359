import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Mfooter';
import Config from '../layout/Config';
import {Helmet} from "react-helmet";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_insurance: [],
            gadget_page: [],
            gadget: [],
            Gadget: [],
            document: ""

        };
    }

    componentDidMount() {


        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');

        const url1 = Config.url + 'api/document';

        fetch(url1, { method: 'GET', headers })
            .then(function(response) {
                return response.json();
            })
            .then(jsonStr => {
                this.setState({
                    document: jsonStr
                });
                if (this.state.document == null) {
                    this.state.document = null;
                }
            });
        console.log(this.state.document)

        const url = Config.url+'api/gadget_page/' + this.props.match.params.id;
  fetch(url, { method: 'GET', headers })
  .then(function(response) {
      return response.json();
  })
    .then(jsonStr => {
    this.setState({ 
      gadget_page: jsonStr
     });
     if(this.state.gadget_page == null){
      this.state.gadget_page = null;
    }
 });
        const url2 = Config.url+'api/gadgetowl';
        fetch(url2, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            gadget: jsonStr ? jsonStr : null
           });
 
           if(this.state.gadget == null){

            this.state.gadget = null;
          }
       });
        
    }
    btnCreateGadget=()=> {

        this.setState({ Gadget: [...this.state.Gadget] })
        localStorage.setItem("test", JSON.stringify(this.state.Gadget));
        localStorage.setItem("glength", JSON.stringify(this.state.Gadget.length));
        window.location = "/quotes/Details";
        }
        gadlink=(link)=>{

        window.location = 'Gadgets/'+link;

        
        }
    render() {
        console.log(this.state.gadget_page);
        const { gadget } = this.state;


        return ( <div>
            <Header />
           
            <div class = "prd-page">
            <div class = "abt-footer">
            <div class = "container">
            <div class = "row" >
            <div class = "col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class = "abt_footer_in text-center">
            <h2> Gadgets </h2>

            </div> </div> </div> </div> </div> </div> 
            <section class="gadgetspg">
                <div class="container">
                    <div class="row">
                    
                    {gadget.map(gadget =>
                        <div class="col-6 col-sm-4 col-md-3">

                        <div class="gadget-item mt-4" onClick={()=>this.gadlink(gadget.slug)}>
                            <img src ={Config.url + gadget.avatar} class="img-fluid" />
                            {/* <h3 class="text-center"> {gadget.title} </h3> */}
                            {/* <button class="text-center" onClick={()=>this.gadlink(gadget.slug)}><i class="fa fa-angle-right"></i> 
                        </button> */}
                        </div>
                        </div>
                        )}
                    
                    
                    
                    </div>
                </div>
            </section>
            

            <Footer/>
            </div>
        );
    }
}
export default Terms;