import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies';
 
class Pgtest extends Component {
  constructor(props) {
    super(props);
    this.state = {
    device : [],
    make : [],
    make1:[],
    options:[],
    label:'',
    device_make: [],
    m:"",


    gadgets: [

      {

        name: "gadget-1",

         make: [],
         makeOptionList: [],

        device: ""

      }

    ]

  };

  }

 onUpdateItem = (index, value, type) => {
    let gadgets = this.state.gadgets;
    
    if (type === "device") {
     
      gadgets[index].device = value;

      const headers = new Headers();
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'multipart/form-data');
      
      const url4 = 'http://localhost:8000/api/device/type/'+ value;
      fetch(url4, { method: 'GET', headers })
      .then(function(response) {
          return response.json();
      })
      .then(jsonStr => {
        this.setState({ 
         
          make: jsonStr.map(make => ({
            label: make.make,
            value: make.id
          }))
         }); 

       gadgets[index].makeOptionList = this.state.make;
       this.setState({ 
            make: gadgets[index].makeOptionList
         });
      // console.log(gadgets[index].makeOptionList);
      });
   }   

    this.setState({
      gadgets: gadgets
    });

  };
  
  onAdd() {
    
    let newGadget = {

      name: `gadget-${this.state.gadgets.length + 1}`,

      make: [],
      makeOptionList: [],

      device: ""
    };

    let gadgets = this.state.gadgets;
    gadgets.push(newGadget);
    this.setState({
      gadgets: gadgets
    });

  }


   componentWillMount() {

      setTimeout(
        function() {
         this.setState({loading: false});
        }
        .bind(this),
        1000
      );
       window.scrollTo(0, 0);
       
    }
  componentDidMount() {
      
      // window

      // .fetch("https://jsonplaceholder.typicode.com/users")

      // .then(responce => responce.json())

      // .then(result => {

      //   this.setState({

      //     options: result.map(user => ({

      //       label: user.username,

      //       value: user.email

      //     }))

      //   });

      // });
    
      const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');

       const url2 = 'http://localhost:8000/api/device';
        fetch(url2, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
        .then(jsonStr => {
        this.setState({ 
         
          device: jsonStr.map(device => ({
            label: device.title,
            value: device.id
          }))
         }); 
      });
      
     }
   

render() {
 
    // const { device } = this.state;
     // const { make } = this.state.make;
    return (

      <div style={{
          height: "100%",

          width: "100%",

          display: "flex",

          flexDirection: "row",

          justifyContent: "space-between" }}>

        {this.state.gadgets.map((m, index) => {

 
  return (
        
    <div style={{ display: "flex", flexDirection: "column" }}>

      <div style={{ display: "flex", flexDirection: "column" }}>

        <span style={{ fontSize: "20px", fontWeight: "bold" }}>

          {m.name}

        </span>
        <p>device</p>

                <select class="select-text" value={m.device} onChange={text =>this.onUpdateItem(index, text.target.value, "device")} required>

                  <option value="" disabled selected />
                  {this.state.device.map(function(item, key) {
                     return (
                       <option key={item.key} value={item.value}> 

                        {item.label}
                         </option>
                      )
                     }.bind(this))
                    } 
                </select>

                <p>make</p>
                 {m.index == 0 ?
                <select class="select-text" value={m.make} 
                onChange={text => this.onUpdateItem(index, text.target.value, "make")} required>
                
                  <option value="" disabled selected />
                  {this.state.make.map(function(item, key) {
                      return (
                        <option key={item.key} value={item.value}>
                          {item.label}</option>
                      )
                  }.bind(this))
                }    
                  </select>
                  :
                  <select class="select-text" value={m.make} 
                onChange={text => this.onUpdateItem(index, text.target.value, "make")} required>
                
                  <option value="" disabled selected />
                  {m.makeOptionList.map(function(item, key) {
                      return (
                        <option key={item.key} value={item.value}>
                          {item.label}</option>
                      )
                  }.bind(this))
                }    
                  </select>
            }
                </div>
              </div>
            );

          })}
        <span>
          <button style={{ height: "50px", width: "100px" }} onClick={() => this.onAdd()}> Add Gadget </button>
        </span>
      </div>
    );

  }

}


 
export default Pgtest;

// import React from "react";

// import ReactDOM from "react-dom";

// import styled from "styled-components";

// import "normalize.css";

// import "./demo-styles.css";

 

// export class App extends React.Component {

//   state = {

//     options: [],

//     devices: [

//       {

//         value: "samsung",

//         label: "samsung"

//       },

//       {

//         value: "apple",

//         label: "apple"

//       },

//       {

//         value: "nokia",

//         label: "nokia"

//       }

//     ],

//     make: [

//       {

//         value: 1999,

//         label: 1999

//       },

//       {

//         value: 2000,

//         label: 2000

//       },

//       {

//         value: 2010,

//         label: 2010

//       }

//     ],

//     gadgets: [

//       {

//         name: "gadget-1",

//         make: "",

//         device: ""

//       }

//     ]

//   };

 

//   componentDidMount() {

//     window

//       .fetch("https://jsonplaceholder.typicode.com/users")

//       .then(responce => responce.json())

//       .then(result => {

//         this.setState({

//           options: result.map(user => ({

//             label: user.username,

//             value: user.email

//           }))

//         });

//       });

//   }

 

//   onUpdateItem = (index, value, type) => {

//     let gadgets = this.state.gadgets;

//     if (type === "device") {

//       gadgets[index].device = value;

//     }

//     if (type === "make") {

//       gadgets[index].make = value;

//     }

//     this.setState({

//       gadgets: gadgets

//     });

//   };

 

//   onAdd() {

//     let newGadget = {

//       name: `gadget-${this.state.gadgets.length + 1}`,

//       make: "",

//       device: ""

//     };

//     let gadgets = this.state.gadgets;

//     gadgets.push(newGadget);

//     this.setState({

//       gadgets: gadgets

//     });

//   }

 

//   render() {

//     return (

//       <div

//         style={{

//           height: "100%",

//           width: "100%",

//           display: "flex",

//           flexDirection: "row",

//           justifyContent: "space-between"

//         }}

//       >

//         {this.state.gadgets.map((m, index) => {

//           return (

//             <div style={{ display: "flex", flexDirection: "column" }}>

//               <div style={{ display: "flex", flexDirection: "column" }}>

//                 <span style={{ fontSize: "20px", fontWeight: "bold" }}>

//                   {m.name}

//                 </span>

 

//                 <p>device</p>

 

//                 <select

//                   class="select-text"

//                   value={m.device}

//                   onChange={text =>

//                     this.onUpdateItem(index, text.target.value, "device")

//                   }

//                   required

//                 >

//                   <option value="" disabled selected />

//                   {this.state.devices.map(

//                     function(item, key) {

//                       return (

//                         <option key={item.key} value={item.value}>

//                           {" "}

//                           {item.label}{" "}

//                         </option>

//                       );

//                     }.bind(this)

//                   )}

//                 </select>

//                 <p>make</p>

 

//                 <select

//                   class="select-text"

//                   disabled={m.device.length === 0}

//                   value={m.make}

//                   onChange={text =>

//                     this.onUpdateItem(index, text.target.value, "make")

//                   }

//                   required

//                 >

//                   <option value="" disabled selected />

//                   {this.state.make.map(

//                     function(item, key) {

//                       return (

//                         <option key={item.key} value={item.value}>

//                           {" "}

//                           {item.label}{" "}

//                         </option>

//                       );

//                     }.bind(this)

//                   )}

//                 </select>

//               </div>

//             </div>

//           );

//         })}

//         <span>

//           <button

//             style={{ height: "50px", width: "100px" }}

//             onClick={() => this.onAdd()}

//           >

//             Add Gadget

//           </button>

//         </span>

//       </div>

//     );

//   }

// }

 

// const StyledNoData = styled.div`

//   padding: 10px;

//   color: #555;

//   background: #f2f2f2;

//   border-radius: 5px;

//   margin: 3px;

// `;

 

// const rootElement = document.getElementById("root");

 

// ReactDOM.render(<App />, rootElement);