import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';


class PaymentData extends Component {
    constructor(props) {
    super(props);
    this.state = {
      paymentDetails:[],
      quote_id:'',
      order_id:'',
      amount:'',
      reference_no:'',
      message:'',
      trans_id:'',
      status_code:''
    };
  
  }
  

componentDidMount() {

      var apiUrl = 'http://127.0.0.1:8000/api/addPaymentResult';
            const data = {
                quote_id: localStorage.getItem('quote_id'),
                order_id: localStorage.getItem('order_id'),
                message: this.props.match.params.id,
                status_code: this.props.match.params.status_code,
                trans_id: this.props.match.params.trans_id
            }
    //console.log(data);
            const headers = new Headers();
            headers.set('Accept', 'application/json');
            headers.set('Content-Type', 'multipart/form-data');
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers 
            };
            fetch(apiUrl, options)
                .then(function(response) {
                return response.json();
               })
              .then(jsonStr => {
              this.setState({ 
                paymentDetails: jsonStr
               })
             
               //console.log(this.state.paymentDetails['response']['status']);
              
                }, (error) => {
                    this.setState({error});

                })

              localStorage.setItem("payment_status", this.props.match.params.status_code);
              if(localStorage.getItem('payment_status') == '0'){
                   localStorage.clear();
                   localStorage.removeItem('test');
                   window.location = "/payment_callback/";
              }
             else{
                localStorage.setItem("payment_status", this.props.match.params.status_code);
                 window.location = "/QuoteSummary/"+localStorage.getItem('quote_id') ;
              }
           this.setState({
                
                fname: localStorage.getItem('hacfname'),
                post: localStorage.getItem('hacpost'),
                email: localStorage.getItem('hacemail'),
                tele: localStorage.getItem('hactele'),
                address: localStorage.getItem('hacaddress'),
                amount: localStorage.getItem('amount'),
                reference_no: localStorage.getItem('reference_no')

            })
     }

render() {

  
  return (
   <div>
  
</div>
);
}
}
export default PaymentData;