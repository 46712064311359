import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies';
import SimpleReactValidator from 'simple-react-validator';
import Sidebanner from '../layout/Sidebanner';
import MetaTags from 'react-meta-tags';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      data1:[],
      device: [],
      make:[],
      make1:[],
      make11:[],
      makeOptionList:[],
      modelOptionList: [],
      rangeOptionList: [],
      model: [],
      device_id:'',
      params: [],
      columnParams: [],
      department: '',
      range: [],
      Gadget: [],
      new_refurb:'',
      text:'',
      device_type_id:'',
      loading:true,
      device_make:[],
      titles: 'yes',
      product:[],
      data:[],
      title: '',
      fname: '',
      lname: '',
      dob: '',
      post: '',
      email: '',
      tele: '',
      address: '',
      items:[],
      add: [],
      quote_id:'',
      makeOptionList: [],
      options:[],
      device_type_id: false,
      api_response:'',
      item_details:[],
      valueprice : [],
      pricevalue:'',
      Gadgets:[],
      error: [
      {
        make_id: false,
        device_type_id: false,
        model_id: false,
        imei: false,
        other: false,
        makes: false,
        models: false,
        range_id: false,
        new_refurb: false,
        age:false,
        retailer:false
        
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      },{
        make_id: false,
        device_type_id: false,
        model_id: false,
        range_id: false,
        imei: false,
        makes: false,
        models: false,
        new_refurb: false,
        age:false,
        retailer:false,
        other: false
      }
    ],
     
      Gadget: [
      {
         name: "gadget-1",
         device_type_id:"",
         make_id:"",
         model_id:"",
         range_id:"",
         imei:"",
         new_refurb:"",
         age:"",
         retailer:"",
         other:"",
         makesfile:"",
         modelsfile:"",
         make: [],
         makeOptionList: [],
         modelOptionList: [],
         rangeOptionList: []
      }
      
    ]
     
    };
    this.validator = new SimpleReactValidator();

  }
  getStartDatePolicy = (value) => {
    // let currentDates = value;
    // let convertDates = moment(currentDates).format('DD-MM-YYYY');
    // this.setState({currentDates,convertDates});
    var today = new Date();
    let currentDates = value.target.value;
    console.log(value.target.value);
    var day = moment(currentDates, "YYYY-MM-DD");
    let convertDates = value.target.value;
    this.setState({convertDates});
    
  }
  handleBlur = (key, text, colname) => {
    const headers = new Headers();
     headers.set('Accept', 'application/json');
     headers.set('Content-Type', 'multipart/form-data');
     this.setState(state => {
     const Gadget = state.Gadget.map((item, j) => {
       if (j === key) {
         return item[colname] = text;
         } else {
         return item;
         }
        });
       return {
       Gadget,
         };
     });
    let Gadget = this.state.Gadget;
    let error = this.state.error;
    // console.log(Gadget);
    var valueprice = 0 ;
   if(colname === 'range_id'){
     Gadget.map(function(item, key) {
       valueprice = parseInt(valueprice) + parseInt(item.range_id) ;
      });
      // console.log(valueprice);
       if(valueprice <= 500){
         this.setState({ 
           pricevalue : 59.99
         });
       }else if(valueprice <= 1000){
         this.setState({ 
           pricevalue : 79.99
         });
       }else if(valueprice > 1000){
        this.setState({ 
          pricevalue : 99.99
        });
      }
    // console.log(this.state.pricevalue);

   }
    
   if(colname === 'device_type_id'){
     Gadget[key].device = text;
     error[key].device_type_id = false;

     //getMake and Rang- plan   
     const url4 = Config.url+'api/device/type/'+ text;
     fetch(url4, { method: 'GET', headers })
     .then(function(response) {
         return response.json();
     })
     .then(jsonStr => {
       this.setState({ 
         make: jsonStr['gadget_makes']
        }); 

      Gadget[key].makeOptionList = this.state.make;
      this.setState({ 
           make: Gadget[key].makeOptionList
       });

      //getRange
      this.setState({ 
         range: jsonStr['gadget_ranges']
        });
      Gadget[key].rangeOptionList = this.state.range;
      this.setState({ 
           range: Gadget[key].rangeOptionList
       });
     });

   }
   //getModel
  if(colname === 'make_id'){

     let Gadget = this.state.Gadget;
     Gadget[key].make_id = text;
     error[key].make_id = false;

     const url4 = Config.url+'api/device/make/'+ text;
     fetch(url4, { method: 'GET', headers })
     .then(function(response) {
         return response.json();
     })
     .then(jsonStr => {
       this.setState({ 
         model: jsonStr
        }); 

      Gadget[key].modelOptionList = this.state.model;
      this.setState({ 
           model: Gadget[key].modelOptionList
       });
     });
   }

    error[key].model_id = false;
    error[key].other = false;
    error[key].imei = false;
    error[key].range_id = false;
    error[key].new_refurb = false;
    error[key].age = false;
    error[key].retailer = false;
   this.setState({
     Gadget: Gadget,
     error: error
   });

 }
//  selectedItem = (event) => {

//   console.log(event);

//  }
  onUpdateItem = (key, text, colname) => {
     const headers = new Headers();
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'multipart/form-data');
      this.setState(state => {
      const Gadget = state.Gadget.map((item, j) => {
        if (j === key) {
          return item[colname] = text;
          } else {
          return item;
          }
         });
        return {
        Gadget,
          };
      });
     let Gadget = this.state.Gadget;
     let error = this.state.error;
     console.log(Gadget);
     var valueprice = 0 ;
    if(colname === 'retailer'){
      Gadget.map(function(item, key) {
        valueprice = parseInt(valueprice) + parseInt(item.range_id) ;
       });
       console.log(valueprice);
        if(valueprice <= 500){
          this.setState({ 
            pricevalue : 59.99
          });
        }else if(valueprice <= 1000){
          this.setState({ 
            pricevalue : 79.99
          });
        }else if(valueprice > 1000){
          this.setState({ 
            pricevalue : 99.99
          });
        }
    }
     
    if(colname === 'device_type_id'){
      Gadget[key].device = text;
      error[key].device_type_id = false;

      //getMake and Rang- plan   
      const url4 = Config.url+'api/device/type/'+ text;
      fetch(url4, { method: 'GET', headers })
      .then(function(response) {
          return response.json();
      })
      .then(jsonStr => {
        this.setState({ 
          make: jsonStr['gadget_makes']
         }); 
 
       Gadget[key].makeOptionList = this.state.make;
       this.setState({ 
            make: Gadget[key].makeOptionList
        });
 
       //getRange
       this.setState({ 
          range: jsonStr['gadget_ranges']
         });
       Gadget[key].rangeOptionList = this.state.range;
       this.setState({ 
            range: Gadget[key].rangeOptionList
        });
      });

    }
    //getModel
  //  if(colname === 'make_id'){

  //     let Gadget = this.state.Gadget;
  //     Gadget[key].make_id = text;
  //     error[key].make_id = false;

  //     const url4 = Config.url+'api/device/make/'+ text;
  //     fetch(url4, { method: 'GET', headers })
  //     .then(function(response) {
  //         return response.json();
  //     })
  //     .then(jsonStr => {
  //       this.setState({ 
  //         model: jsonStr
  //        }); 

  //      Gadget[key].modelOptionList = this.state.model;
  //      this.setState({ 
  //           model: Gadget[key].modelOptionList
  //       });
  //     });
  //   }

    //  error[key].model_id = false;
    //  error[key].other = false;
    //  error[key].imei = false;
    //  error[key].range_id = false;
    //  error[key].new_refurb = false;
    //  error[key].age = false;
    //  error[key].retailer = false;
    this.setState({
      Gadget: Gadget,
      error: error
    });

  }
  componentWillMount() {
      
      if(localStorage.getItem('glength') <= 0 && localStorage.getItem('quote_id') == null){
        window.location = "/";
      }
      setTimeout(
        function() {
         this.setState({loading: false});
        }
        .bind(this),
        1000
      );
       window.scrollTo(0, 0);
    var data1 = JSON.parse(localStorage.getItem('test'));
    var planprice = JSON.parse(localStorage.getItem('planprice'));
    // var startdateofpolicy = JSON.parse(localStorage.getItem('startdateofpolicy'));
    

    console.log(planprice);
    this.setState({
      pricevalue: planprice,
      convertDates: localStorage.getItem("startdateofpolicy"),
    });
    var glength = localStorage.getItem('glength');
    if(data1){
       this.getGadgets();
     }
  }
  componentDidMount() {
      
      var data1 = JSON.parse(localStorage.getItem('test'));
      this.setState({  
            make1: data1
          });
      const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');
       const url2 = Config.url+'api/device';
        fetch(url2, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            device: jsonStr ? jsonStr : null
          });
         
       });
  }
   
  getGadgets() {

      if(localStorage.getItem('glength') <= 0){
         let newlyAddedValue = {
            index : this.index + 1
          }
         let newErrorState = {
          make_id: false,
          device_type_id: false,
          model_id: false,
          other: false,
          imei: false,
          range_id: false,
          new_refurb: false,
          age: false,
          retailer: false
          };

          let error = this.state.error;
          this.setState({ error: [...this.state.error, newErrorState],error: error});
          this.setState({ Gadget: [...this.state.Gadget , newlyAddedValue],error: error }, () => {
          this.index = this.index + 1;
          });
         }else{
          let newErrorState = {
            make_id: false,
            device_type_id: false,
            model_id: false,
            other: false,
            imei: false,
            range_id: false,
            new_refurb: false,
            age: false,
            retailer: false
          };
          let error = this.state.error;
          this.setState({ error: [error, newErrorState]});
          this.setState({Gadget: localStorage.getItem('test'),error: error}); 
          var ownersbind = [];
          ownersbind = JSON.parse(localStorage.getItem('test'));
          this.setState({ loading: false, disabled: true, Gadget: ownersbind,error: error }, () => {
           var gfd = JSON.parse(localStorage.getItem('glength'));
           this.index = gfd + 1;
          this.setState({ disabled: false });
          });
          }
        }
  btnCreateGadget=()=> {
    let error = this.state.error;
    // debugger;
    let errorflag = 0;
    for (let i = 0; i < this.state.Gadget.length; i++) {
     let ownerrow = this.state.Gadget[i];
     if (ownerrow.device_type_id === "" || ownerrow.device_type_id === null || ownerrow.device_type_id === undefined) {
       error[i].device_type_id = true;
       errorflag = 1;
       break;
      }
      // else if (ownerrow.make_id == '' || ownerrow.make_id == null || ownerrow.make_id == undefined) {
      //  error[i].make_id = true;
      //  errorflag = 1;
      //  break;
      // }
      // else if (ownerrow.model_id == '' || ownerrow.model_id == null || ownerrow.model_id == undefined) {
      //  error[i].model_id = true;
      //  errorflag = 1;
      //  break;
      // }
      else if (ownerrow.imei == '' || ownerrow.imei == null || ownerrow.imei == undefined) {
       error[i].imei = true;
       errorflag = 1;
       break;
      }
      else if (ownerrow.range_id == '' || ownerrow.range_id == null || ownerrow.range_id == undefined) {
       error[i].range_id = true;
       errorflag = 1;
       break;
      } else if (ownerrow.new_refurb == '' || ownerrow.new_refurb == null || ownerrow.new_refurb == undefined) {
       error[i].new_refurb = true;
      var fff = error[i].new_refurb;
       errorflag = 1;
       break;
      }else if (ownerrow.age == '' || ownerrow.age == null || ownerrow.age == undefined) {
        error[i].age = true;
       var fff = error[i].age;
        errorflag = 1;
        break;
      }else if (ownerrow.retailer == '' || ownerrow.retailer == null || ownerrow.retailer == undefined) {
        error[i].retailer = true;
       var fff = error[i].retailer;
        errorflag = 1;
        break;
      }
      else if (ownerrow.model_id == 'others'){
       if (ownerrow.other == '' || ownerrow.other == null || ownerrow.other == undefined) {
       error[i].other = true;
       errorflag = 1;
       break;
       } 
      }
      else {
      errorflag = 0;
      }
    }
    if (this.validator.allValid()) {
        this.validateGadgetPage();
    }else{
        this.validator.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        this.forceUpdate();
    }
  }
  validateGadgetPage() {
        localStorage.setItem("test", JSON.stringify(this.state.Gadget));
        localStorage.setItem("glength", JSON.stringify(this.state.Gadget.length));
        localStorage.setItem("planprice", this.state.pricevalue);
        localStorage.setItem("startdateofpolicy", this.state.convertDates);
        
        var apiUrl =  Config.url+'api/addItem';
        var dd  = localStorage.getItem('test');
        var quote_id  = localStorage.getItem('quote_id');
        console.log(dd);
            const data = {
                quote_id:quote_id,
                planprice:this.state.pricevalue,
                product: JSON.parse(dd)
            }
            
            console.log(data);
          const headers = new Headers();
            headers.set("Accept", "application/json");

            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers
            };
            fetch(apiUrl, options)
                .then(function(response) {
                return response.json();
               })
              .then(jsonStr => {
                  this.setState({ 
                    items: jsonStr
                   })
                   console.log(this.state.items);
                  const url = Config.url+'api/gadget-list-name/'+ localStorage.getItem('quote_id');
                  fetch(url, { method: 'GET', headers })
                  .then(function(response) {
                      return response.json();
                  })
                  .then(jsonStr => {
                      this.setState({ 
                        gadget_list: jsonStr,
                       });
                       console.log(jsonStr);
          //             
                      const data = {
                        reference_id: localStorage.getItem('reference_no'),
                        item_details: this.state.gadget_list,
                        startdatepolicy : this.state.convertDates
                      }

                      const crmstep2 = Config.crm+'processstep2';
                      const options1 = {
                          method: 'POST',
                          body: JSON.stringify(data),
                          headers
                      };
                      fetch(crmstep2, options1)
                      .then(function(response) {
                         return response.json();                         
                      })

                      .then(jsonStr => {
                        this.setState({ 
                          api_response: jsonStr
                         })
                         console.log(this.state.api_response);
                        window.location = "/quotes/Plan";
                      }, (error) => {
                        this.setState({error});
                      })
                            }, (error) => {
                                  this.setState({error});
                      }
                  )
              })        

    }
  addMore = () => {

    if (this.validator.allValid()) {
      let newlyAddedValue = { 
        index : this.index + 1,
        makeOptionList: [],
        modelOptionList: [],
        rangeOptionList: [],
        other:""
       };

       let newErrorState = {
        // make_id: false,
        device_type_id: false,
        // model_id: false,
        // other: false,
        imei: false,
        range_id: false,
        new_refurb: false
      };
      
      let Gadget = this.state.Gadget;
      let error = this.state.error;
      
      this.setState({ error: [...this.state.error, newErrorState],error: error});

      this.setState({ Gadget: [...this.state.Gadget, newlyAddedValue],error: error}, () => {
      this.index = this.index + 1;
      });

    }else{
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  ondeleteOwner = (i) => {
    const list = this.state.Gadget.filter((item, j) => i !== j);
    this.setState({ loading: false, disabled: true, Gadget: list }, () => {
        this.index = this.index - 1;
        this.setState({ disabled: false });

    });
    var valueprice = 0;
    list.map(function(item, key) {
      valueprice = parseInt(valueprice) + parseInt(item.range_id) ;
     });
      if(valueprice <= 500){
        this.setState({ 
          pricevalue : 59.99
        });
      }else if(valueprice <= 1000){
        this.setState({ 
          pricevalue : 79.99
        });
      }else{
        this.setState({ 
          pricevalue : 99.99
        });
      }
  };
  render() {
    const { device } = this.state;
   const {currentDates,convertDates} = this.state;
    let newArray = this.state.Gadget.map((item, key) => { 
     if(item.makeOptionList == null){
          item.makeOptionList = this.state.make1;
     }
     if(item.modelOptionList == null){
          item.modelOptionList = this.state.model;
     }
     if(item.rangeOptionList == null){
          item.rangeOptionList = this.state.range;
     }
     const makes = item.makeOptionList;
     const modelss = item.modelOptionList;
     let consc = key + 1;
      if ((key) == this.index) {
       return (
         <div key={key}>
           <h5 class="textee"> Gadget {consc} </h5>
         </div>
       );
     }
     else {
       return (
         <div index={key}>
               <div>
                   <div class="prd-frm-sec">
                       <form class="product-form">
                           <div class="row">
                               <div class="col-xs-6 col-sm-6 col-md-6">
                                   <h5> Gadget {consc} </h5>
                               </div>
                                 <div class="col-xs-6 col-sm-6 col-md-6">
                                   {consc !=1 &&
                                      <button type="button" class="dlt-gdt remove_field" onClick={() => this.ondeleteOwner(key)}><i class="fa fa-trash" aria-hidden="true"></i> Delete Gadget</button>                                 
                                   }
                                 </div>
                               <div class="col-md-6">
                                  <div class="form-group">
                                  <FormControl variant="outlined" className="form_control">
                                    <InputLabel htmlFor="outlined-device-type-native-simple">Device Type</InputLabel>
                                    <Select
                                      native
                                      value={device.length > 0 && item.device_type_id}
                                      onChange={(text) => this.onUpdateItem(key, text.target.value, 'device_type_id')}
                                      label="Device Type"
                                      labelId="outlined-device-type-native-simple"
                                      name="device_type_id"   
                                      id="outlined-device-type-native-simple"                                  
                                    >
                                      <option aria-label="None" value="" />
                                      {device.map((item, key) => <option value={item.id} > {item.title} </option>)}
                                    </Select>
                                  </FormControl>
                                  {this.validator.message('Device type', item.device_type_id, 'required')}

                                  </div>
                               </div>
                               <div class="col-md-6">
                                  <div className="form-group">
                                     <TextField label="Make"
                                        name={'makesfile[' + key +']'} 
                                        value={item.makesfile}
                                        onChange={(text) => this.onUpdateItem(key, text.target.value, 'makesfile')}
                                        className="form_control"
                                        variant="outlined" />
                                        {this.validator.message('Device Make', item.makesfile, 'required')}
                                  </div>
                                  
                               </div>
                               <div class="col-md-6">
                                    <div class="form-group">
                                      <TextField label="Model"
                                      name={'modelsfile[' + key +']'} 
                                      value={item.modelsfile}
                                      onChange={(text) => this.onUpdateItem(key, text.target.value, 'modelsfile')}
                                      className="form_control"
                                      variant="outlined" />
                                      {/* {this.validator.message('Device Model', item.modelsfile, 'required')} */}
                                      {this.validator.message('Device model', item.modelsfile, 'required')}
                                  </div>
                               </div>
                               
                                
                                <div class="col-md-6">
                                    <div class="form-group">
                                      <TextField label="IMEI/Serial Number"
                                      name={'imei[' + key +']'} 
                                      value={item.imei}
                                      onChange={(text) => this.onUpdateItem(key, text.target.value, 'imei')}
                                      className="form_control"
                                      variant="outlined" />
                                      {/* {this.validator.message('', item.imei, 'required|numeric|min:15|max:15'), {messages: {required: 'This field is required2323', '': 'Some message'}} } */}
                                      {this.validator.message('numbers', item.imei, 'required')}
                                    </div>
                               </div>
                               
                               <div class="col-md-6">
                                    <div class="form-group">
                                      <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-purchase-price">Purchase Price</InputLabel>
                                          <OutlinedInput
                                            label="Purchase Price"
                                            id="outlined-purchase-price"
                                            value={item.range_id}
                                            name={'range_id[' + key +']'} 
                                            onChange={(text) => this.onUpdateItem(key, text.target.value, 'range_id')}
                                            onBlur={(text) => this.handleBlur(key, parseFloat(text.target.value).toFixed(2), 'range_id')}
                                            startAdornment={<InputAdornment position="start">£</InputAdornment>}
                                            labelWidth={60}
                                          />
                                      </FormControl>
                                        {this.validator.message('Purchase Price', item.range_id, 'required|numeric')} 
                                     
                                    </div>
                               </div>
                               <div class="col-md-6">
                                  <div class="form-group">
                                    <FormControl variant="outlined" className="form_control">
                                        <InputLabel htmlFor="outlined-new-refurb-native-simple">New/Refurbished</InputLabel>
                                        <Select
                                          native
                                          value={item.new_refurb}
                                          name="new_refurb"
                                          onChange={(name) => this.onUpdateItem(key,name.target.value,'new_refurb')}
                                          label="New/Refurbished"
                                        >
                                          <option value="" disabled selected></option>
                                          <option value="1">New</option>
                                          <option value="2">Refurbished</option>
                                        </Select>
                                    </FormControl>
                                    {this.validator.message('New/Refurbished', item.new_refurb, 'required')}
                                  </div>
                               </div>
                               
                               <div class="col-md-6">
                                  <div class="form-group">
                                    <FormControl variant="outlined" className="form_control">
                                        <InputLabel htmlFor="outlined-age-native-simple">Age</InputLabel>
                                        <Select
                                          native
                                          value={item.age}
                                          name="age"
                                          onChange={(name) => this.onUpdateItem(key,name.target.value,'age')}
                                          label="Age"
                                        >
                                          <option value="" disabled selected></option>
                                          <option value="0-3">0-3 Months</option>
                                          <option value="3-6">3-6 Months</option>
                                          <option value="6-9">6-9 Months</option>
                                          <option value="9-12">9-12 Months</option>
                                          <option value="12-15">12-15 Months</option>
                                          <option value="15-18">15-18 Months</option>
                                          <option value="18-21">18-21 Months</option>
                                          <option value="21-24">21-24 Months</option>
                                          <option value="24-27">24-27 Months</option>
                                          <option value="27-30">27-30 Months</option>
                                          <option value="30-33">30-33 Months</option>
                                          <option value="33-36">33-36 Months</option>
                                        </Select>
                                    </FormControl>
                                    {this.validator.message('Age', item.age, 'required')}
                                  </div>
                               </div>
                               
                               <div class="col-md-6">
                                  <div class="form-group">
                                    <TextField label="Retailer(Excludes any auction sites)"
                                    name={'retailer[' + key +']'} 
                                    value={item.retailer}
                                    onChange={(text) => this.onUpdateItem(key, text.target.value, 'retailer')}
                                    className="form_control"
                                    variant="outlined" />
                                    {this.validator.message('Retailer', item.retailer, 'required|alpha')}
                                  </div>
                               </div>
                           </div>
                       </form>
                   </div>
                   <div class="prd-frm-sec input_field"></div>
                   <div class="bottom-btns">
                     {this.state.Gadget.length == 1 && 
                     <div className="bottom_block">
                      <div class="row justify-content-between align-items-center">
                           <div className="col-md-5">{console.log(this.state.pricevalue)}
                             {this.state.pricevalue == null ?
                             <div className="price_block"></div>
                             :
                             <div className="price_block">
                             £{this.state.pricevalue}<span>Annually</span>
                             </div>
                             }
                           </div>
                           <div className="col-md-7">
                             <div class="text-right">
                                 <a href="./Details"><button class="back_btn">Back</button></a>&nbsp;&nbsp;
                                 <button class="add_field ad_gad " onClick={this.addMore}>Add Gadget</button>&nbsp;&nbsp;
                                 <button class="nxt_btn" onClick={this.btnCreateGadget}>Next</button>
                             </div>
                           </div>
                         {/* <div className="col-12">
                           <button class="px-2 mt-2 offer-btn d-none">
                                         Add Another Gadget TO GET 10% OFF
                           </button>
                         </div> */}
                       </div>
                     </div>
                     }
                   </div>
         </div>
</div>
);
}
});
 return (  
<div>
 <Header />
 <MetaTags>
           <title>Gadgets Quote</title>
           <meta name="description" content="Some description." />
           <meta property="og:title" content="MyApp" />
           <meta property="og:image" content="path/to/image.jpg" />
         </MetaTags>
   <section id="pg-produc">
     <div className="container ">
       <div class="row">
           {/* <div class="col-md-4 col-lg-4">
             <Sidebanner/>
           </div> */}
           <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
               <div class="gen-your-quote">
                   <div class="row">
                       <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                           <h3>Generate your Quote</h3>
                           <p>With just a few simple steps you will receive a personalised quote</p></div>
                   </div>
                   <div class="pg-details-step">
                       <div class="row justify-content-lg-around">
                           <div class="col-md-12 col-lg-2 pd-steps active">
                               <span class="prvstep"><i class="fa fa-check" aria-hidden="true"></i></span> Your Details <i class="fas fa-angle-right"></i>
                           </div>
                           <div class="col-md-12 col-lg-2 pd-steps active mbactive">
                               <span class="activespan">2</span> Product Details <i class="fas fa-angle-right"></i>
                           </div>
                           <div class="col-md-12 col-lg-2 pd-steps">
                               <span>3</span> Product Review <i class="fas fa-angle-right"></i>
                           </div>
                           <div class="col-md-12 col-lg-2 pd-steps">
                               <span>4</span> Payment Methods
                           </div>
                           <div class="col-md-12 col-lg-2 pd-steps a_change">
                               <span>5</span> Policy Summary
                          </div>
                       </div>
                   </div>
                   <div class="pl-dt-frm-sec mt-5 product-form">
                   <div class="row">
                     <div class="col-md-6">
                      <div class="">
                          <TextField
                                variant="outlined"
                                label="Start Date of Policy"
                                type="date"
                                className="form_control"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={convertDates}
                                onChange={(value)=>this.getStartDatePolicy(value)}
                                />
                                {/* <span class="fm_er" id="s_d_po_er">{this.state.s_d_po_er}</span> */}
                                {this.validator.message(
                                  "Start Date of Policy",
                                  convertDates,
                                  "required"
                                )}
                          {/* <div class="md-form date-picker-wrap">
                              <DatePicker 
                                onChange={(value)=>this.getStartDatePolicy(value)}
                                popperPlacement="bottom"
                                popperModifiers={{
                                  flip: {
                                      behavior: ['bottom'] // don't allow it to flip to be above
                                  },
                                  preventOverflow: {
                                      enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                  },
                                  hide: {
                                      enabled: false // turn off since needs preventOverflow to be enabled
                                  }
                              }}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                selected={currentDates}
                                value={convertDates}
                                dropdownMode="select"
                                minDate={moment().toDate()}
                                placeholderText="Start Date of Policy"
                                className="form-control datepicker bdinput bdinputnew" />
                            <span class="fm_er" id="s_d_po_er"></span>
                            {this.validator.message(
                              "Start Date of Policy",
                              convertDates,
                              "required|s_d_po_er"
                            )} 
                          </div> */}
                        </div>
                         {/* <div class="md-form date-picker-wrap">
                             <DatePicker 
                               onChange={(value)=>this.getStartDatePolicy(value)}
                               popperPlacement="bottom"
                               popperModifiers={{
                                 flip: {
                                     behavior: ['bottom'] // don't allow it to flip to be above
                                 },
                                 preventOverflow: {
                                     enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                 },
                                 hide: {
                                     enabled: false // turn off since needs preventOverflow to be enabled
                                 }
                             }}
                               peekNextMonth
                               showMonthDropdown
                               showYearDropdown
                               selected={currentDates}
                               value={convertDates}
                               dropdownMode="select"
                               minDate={moment().toDate()}
                               placeholderText="Start Date of Policy"
                               className="form-control datepicker bdinput bdinputnew" />
                           <span class="fm_er" id="s_d_po_er"></span>
                           {this.validator.message(
                             "Start Date of Policy",
                             convertDates,
                             "required|s_d_po_er"
                           )} 
                         </div> */}
                       </div>
                   </div>
                   </div>
                   
                   {
                     newArray
                   }
                   <div class="prd-frm-sec input_field"></div>
                     <div class="bottom-btns">
                     {this.state.Gadget.length != 1 && 
                     <div className="bottom_block">
                      <div class="row justify-content-between align-items-center">
                           <div className="col-md-5">
                             <div className="price_block">
                             £{this.state.pricevalue}<span>Annually</span>
                             </div>
                           </div>
                           <div className="col-md-7">
                             <div class="text-right">
                                 <a href="./Details"><button class="back_btn">Back</button></a>&nbsp;&nbsp;
                                 <button class="add_field ad_gad " onClick={this.addMore}>Add Gadget</button>&nbsp;&nbsp;
                                 <button class="nxt_btn" onClick={this.btnCreateGadget}>Next</button>
                             </div>
                           </div>
                         {/* <div className="col-12">
                           <button class="px-2 mt-2 offer-btn d-none">
                                         Add Another Gadget TO GET 10% OFF
                           </button>
                         </div> */}
                       </div>
                     </div>
                     }
                     </div>
               </div>
           </div>
       </div>
       </div>
   </section>
 <Footer />
</div>  
);  
}
}
export default Products;