import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import OwlCarousel from 'react-owl-carousel2';
import {Helmet} from "react-helmet";

const options = {
    items: 1,
    nav: true,
    smartSpeed:2000,
    autoplay: true,
    loop:true,
    // touchDrag:false,
    responsive:{
                    0:{
                        items:1
                    },
                    650:{
                        items:2
                    },
                    768:{
                        items:3
                    },
                    973:{
                        items:3
                    },
                    1000:{
                        items:4
                    },
                },
};
const options2 = {
  items: 1,
    autoplay: true,
    loop:true,
    mouseDrag:false,
    autoplayTimeout:10000,
    autoplaySpeed :1000,

    touchDrag:false,
    responsive:{
                    0:{
                        items:1
                  
                    },
                    1000:{
                        items:1
                    },
                },
};

 class Home extends Component {
 
   constructor(props) {
        super(props);
        this.state = {
              data:'',
              data1:'',
              data2:'',
              data3:'',
              data4:'',
              data5:'',
              slider:[],
              slider1:'',
              slider2:'',
              slider3:'',
              seo:'',
              seo2:'',
              gadget: [],
              hits: [],
              device: [],
              make:[],
              model:[],
              range:[],
              Gadget:[],
              Items:[],
              item:[],
              item_value:'',
              key :0
          };
          this.index = 1;
    }
    componentWillMount() {
      setTimeout(
        function() {
         this.setState({loading: false});
        }
        .bind(this),
        1000
      );
        window.scrollTo(0, 0);
        // this.getGadgets();
        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');
        const urls7 = Config.url+'api/seo';
        fetch(urls7, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
            console.log(jsonStr);
          this.setState({ 
            seo: 'covermygadget',
            seo2: 'test',
           });
       });
    }
    componentDidMount() {
       
      const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');
        const url = Config.url+'api/gallery';
        fetch(url, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 

            data: jsonStr[0] ? jsonStr[0] : null,
            data1: jsonStr[1] ? jsonStr[1] : null,
            data2: jsonStr[2] ? jsonStr[2] : null,
            data3: jsonStr[3] ? jsonStr[3] : null,
            data4: jsonStr[4] ? jsonStr[4] : null,
            data5: jsonStr[5] ? jsonStr[5] : null,

           });
       });

       const url1 = Config.url+'api/slider';
        fetch(url1, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            slider: jsonStr,
            slider1: jsonStr[0],
            slider2: jsonStr[1],
            slider3: jsonStr[2],

           });
        console.log(this.state.slider)
          // if(this.state.slider == null){

          //   this.state.slider = null;
          // }
          // if(this.state.slider1 == null){

          //   this.state.slider1 = null;
          // }
          
       });
       console.log(this.state.slider)

       const url2 = Config.url+'api/gadgetowl';
        fetch(url2, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            gadget: jsonStr ? jsonStr : null
           });
 
           if(this.state.gadget == null){

            this.state.gadget = null;
          }
       });

      
  

     

 //for getting first item.in_carousel
       const url7 = Config.url+'api/getProducts';
        fetch(url7, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            item: jsonStr[0]
           });
         if(this.state.item == null){

            this.state.item = null;
          }
        });
       
    }
    // getGadgets() {
    //   if(localStorage.getItem('glength') <= 0){

    //      let newlyAddedValue = { index: this.index }
    //      this.setState({ Gadget: [...this.state.Gadget , newlyAddedValue] }, () => {
    //      this.index = this.index + 1;
    //     });
    //     }else{
    //       this.setState({Gadget: localStorage.getItem('test'),}); 
    //       var ownersbind = [];
    //       ownersbind = JSON.parse(localStorage.getItem('test'));
    //       this.setState({ loading: false, disabled: true, Gadget: ownersbind }, () => {
    //        var gfd = JSON.parse(localStorage.getItem('glength'));
    //        this.index = gfd + 1;
    //       this.setState({ disabled: false });
    //       });
    //       }
    //     }
        gadlink=(link)=>{

          window.location = 'Gadgets/'+link;

          
        }
    // onUpdateItem = (i,text, colname) => {
   
    //   this.setState(state => {
    //   const valueArray = state.Gadget.map((item, j) => {
    //     if (i===j) {
        
    //       return item[colname] = text;
    //       } else {
    //       return item;
    //       }
    //      });

    //     return {
    //     valueArray,
    //         };
    //      });
    // };
    btnCreateGadget=()=> {

      this.setState({ Gadget: [...this.state.Gadget] })
      localStorage.setItem("test", JSON.stringify(this.state.Gadget));
      localStorage.setItem("glength", JSON.stringify(this.state.Gadget.length));
      window.location = "/quotes/Details";
    }

  render() {
    
    const { gadget } = this.state;
    const { slider } = this.state;

    const { item_value } = this.state;
    console.log(this.state.seo);
   
// console.log(slider)
    const styles = {
    imag: {
        backgroundImage: `url(${Config.url + this.state.data.avatar})`
    },
    imag1: {
        backgroundImage: `url(${Config.url + this.state.data1.avatar})`
    },
    imag2: {
        backgroundImage: `url(${Config.url + this.state.data2.avatar})`
    },
    imag3: {
        backgroundImage: `url(${Config.url + this.state.data3.avatar})`
    },
    imag4: {
        backgroundImage: `url(${Config.url + this.state.data4.avatar})`
    },
    imag5: {
        backgroundImage: `url(${Config.url + this.state.data5.avatar})`
    },
    slider_img: {
        backgroundImage: `url(${Config.url + this.state.slider.avatar})`
    },
    slider_img1: {
        backgroundImage: `url(${Config.url + this.state.slider1.avatar})`
    }, 
};
 let key = 0;
 let item = 0;
    return (   
    <div>  
  <Header />
  <Helmet>
     <meta charSet="utf-8" />
     <title>{this.state.seo}</title>
     <meta name="description" content={this.state.seo2} />
     <link rel="canonical" href="www.covermygadget.com" />
 </Helmet>
  <section id="cover">
    <div class="cover">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 margin-dl hide-767">
        <OwlCarousel options={options2} >
       
                 <div class='slide slide1' style={{backgroundImage: 'url(' + Config.url + this.state.slider1.avatar + ')'}}></div>
                 <div class='slide slide2' style={{backgroundImage: 'url(' + Config.url + this.state.slider2.avatar + ')'}}></div>
                 <div class='slide slide3' style={{backgroundImage: 'url(' + Config.url + this.state.slider3.avatar + ')'}}></div>
       
                {/* <div class='slide slide2'></div>
                <div class='slide slide3'></div> */}
        </OwlCarousel>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 margin-dl view-767">
        <OwlCarousel options={options2} >
                 <div class='slide slide1' style={{backgroundImage: 'url(' + Config.url + this.state.slider1.avatarm + ')'}}></div>
                 <div class='slide slide2' style={{backgroundImage: 'url(' + Config.url + this.state.slider2.avatarm + ')'}}></div>
                 <div class='slide slide3' style={{backgroundImage: 'url(' + Config.url + this.state.slider3.avatarm + ')'}}></div>
       
                {/* <div class='slide slide2'></div>
                <div class='slide slide3'></div> */}
        </OwlCarousel>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 margin-dl">
          <div class="cvr-right animated bounceInRight">
          <div class="owl-carousel owl-theme">
          <OwlCarousel options={options2} >

        
          <div class="cvr-right-inner slides2 slide1" >
                     <div dangerouslySetInnerHTML={{__html: this.state.slider1.description}}></div>
                  </div>
                  <div class="cvr-right-inner slides2 slide2" >
                     <div dangerouslySetInnerHTML={{__html: this.state.slider2.description}}></div>
                  </div>
                  <div class="cvr-right-inner slides2 slide3" >
                     <div dangerouslySetInnerHTML={{__html: this.state.slider3.description}}></div>
                  </div>
          
                  
        </OwlCarousel>
             
                </div>

          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="qquote-sec new-change">
    <div class="container">

      <div class="qquote-sec">
        <div class="row">
        </div>
       </div>
    </div>
  </section>
  <section id="sel-and-prot-sec">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="sel-and-prot-in sel-and-prot-in1" style={styles.imag}>
              <h3> {this.state.data.title} </h3>
              <p> {this.state.data.description}.</p>
               <a href={this.state.data.button_link}><button>  Select Your Gadget </button></a>  
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div class="sel-and-prot-in sel-and-prot-in2" style={styles.imag1}>
              <h3> {this.state.data1.title} </h3>
          
              <p> {this.state.data1.description} </p>
                <a href={this.state.data1.button_link}><button>  Apply Now </button></a> 
            </div>
        </div>
      </div>
    </div>
  </section>
  <section id="featured">
    <div class="featured_in">
      <div class="container">
          <h2 class="text-center">Featured Categories</h2>

        <div class="row">
        <OwlCarousel  options={options}  class="owl-carousel">
        
          {gadget.map(gadget =>
            <div class="owlprotect">

              <div class="in_carousel consoli_ins_carosel" onClick={()=>this.gadlink(gadget.slug)}>
                <img src ={Config.url + gadget.avatar} class="img-fluid" />
                {/* <h3 class="text-center"> {gadget.title} </h3> */}
                {/* <button class="text-center" onClick={()=>this.gadlink(gadget.slug)}><i class="fa fa-angle-right"></i> 
               </button> */}
              </div>
            </div>
            )}
          
        </OwlCarousel>
          
           
          
        </div>
      </div>
    </div>
  </section>
  <section id="display_sec">
    <div class="container">
      <div class="row">
        <div class="hidden-xs hidden-sm col-md-3 col-lg-3 no-mrg l-mrg">
            <div class="pay_safe text-center">
              <h4> {this.state.data2.title} </h4>
              <p> {this.state.data2.description} </p>
              <button class="petnow_button"> <a href={this.state.data2.button_link}> Apply Now </a></button>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 no-mrg">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="camcorder">
                     <div class="csm_ct">
                        <h4> {this.state.data3.title} </h4>
                          <button class="petnow_button"> <a href={this.state.data3.button_link}> Apply Now </a></button>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="smart_watch">
                      <h4> {this.state.data4.title} </h4>
                      <p> {this.state.data4.description} </p> 
                      <button class="petnow_button">  <a href={this.state.data4.button_link}> Apply Now </a></button>
                    </div>
                   
                  <div class="listening_time">
                    <h4> {this.state.data5.title} </h4>
                    <p> {this.state.data5.description} </p>
                    <button class="petnow_button"> <a href={this.state.data4.button_link}> Apply Now </a> </button>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  
  <section id="display_sec_sm">
    <div class="display_sec_sm">
      <div class="row">
        <div class="col-xs-12 col-sm-12">
          <div class="top1">
            <div class="toptxt">
            <h4> {this.state.data3.title} 
            </h4>
              {/* <h4>Camcorder insurance from 
                <br/>Cover My Gadget</h4> */}
                 <button class="petnow_button"> <a href={this.state.data3.button_link}> Apply Now </a> </button>
              </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 mobmarg">
            <div class="mid-left">
              <h4>{this.state.data2.title}</h4>
              <p>{this.state.data2.description}</p>
              <button class="petnow_button"> <a href={this.state.data2.button_link}> Apply Now </a> </button>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 mobmarg">
          <div class="mid-right">
            <h4>{this.state.data5.title}</h4>
                    <p>{this.state.data5.description}</p>
                    <button class="petnow_button"> <a href={this.state.data5.button_link}> Apply Now </a> </button>
          </div>
      </div>
      <div class="col-xs-12 col-sm-12">
          <div class="bottom1">
            <h4>{this.state.data4.title}</h4>
            <p>{this.state.data4.description}</p> 
            <button class="petnow_button"> <a href={this.state.data4.button_link}> Apply Now </a> </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Footer />
</div>
  );
  }
}
export default Home;
