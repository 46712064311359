import React, {Component} from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import moment from 'moment';
import Sidebanner from '../layout/Sidebanner';
import MetaTags from 'react-meta-tags';

class Thankyoupage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentDetails: [],
            api_response: ''
        };
    }
    render() {

        return (
            <div>
                <Header/>
                <MetaTags>
            <title>Gadgets Quote</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
                <section id="pg-produc">
                    <div class="container">
                    <div class="row">
                        {/* <div class="col-md-4 col-lg-4">
                            <Sidebanner/>
                        </div> */}
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="gen-your-quote">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h3>Generate your Quote</h3>
                                        <p>With just a few simple steps you will receive a personalised quote</p>
                                    </div>
                                </div>
                                <div class="pg-details-step">
                                <div class="row justify-content-lg-around">
                                        <div class="col-md-12 col-lg-2 pd-steps active">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Your Details
                                            <i class="fas fa-angle-right"></i>
                                        </div>
                                        <div class="col-md-12 col-lg-2 pd-steps active">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Product Details
                                            <i class="fas fa-angle-right"></i>
                                        </div>
                                        <div class="col-md-12 col-lg-2 pd-steps active mbactive">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Product Review 
                                            <i class="fas fa-angle-right"></i>
                                        </div>
                                        <div class="col-md-12 col-lg-2 active pd-steps">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Payment Methods
                                        </div>
                                        <div class="col-md-12 col-lg-2 pd-steps active">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span> Policy Summary
                                        </div>
                                    </div>
                                </div>
                                <div class="sucess-sec text-center">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <i class="fa fa-check sucess-ico mt-5" aria-hidden="true"></i>
                
                                            <h2 class="pt-3">Your Application is Successfully submitted. </h2>
                                            <h5 class="pt-3 pay-succ">
                                                Thank you
                                            </h5>
                                            <p class="pt-3">"We invite you to explore our other products and if you have any
                                                questions, please do not hesitate to reach out to our team."

                                            </p>

                                            <a href={Config.siteurl}>
                                                <button class="bt-5">Back to home</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}
export default Thankyoupage;
