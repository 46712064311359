import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OwlCarousel from 'react-owl-carousel2';


const options = {
    items: 1,
    nav: true,
    autoplay: true,
    responsive: true,
};
var divStyle = {
    width: '300',
    display: 'inline-block',
  };
 class Carousel extends Component {
  render() {
    return (   
  
        <OwlCarousel options={options}
        className="owl-theme"
        loop
        margin={10}
        nav
    >
         <div class="item"><img src="images/banner-1.png" style={divStyle}/></div>
         <div class="item"><img src="images/banner-2.png" style={divStyle}/></div>
         <div class="item"><img src="images/banner-3.png" style={divStyle}/></div>
         <div class="item"><img src="images/banner-4.png" style={divStyle}/></div>
               <div class="item"><img src="images/banner-img-1.jpg" style={divStyle}/></div>
    </OwlCarousel>
 );
}
}
export default Carousel;