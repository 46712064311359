import React, { Component } from 'react';
import Config from '../layout/Config';
import { Lines } from 'react-preloaders';
import {Helmet} from "react-helmet";
import CookieConsent, { Cookies } from "react-cookie-consent";
 class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
          gadget: [],
          document:""

      };
    this.gagdetPage = this.gagdetPage.bind(this);
}

gagdetPage(event) {
   window.location = "/Gadgets/" + event;
}


 componentDidMount() {

  
      
        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'application/json');

        const url1 = Config.url+'api/document';

        fetch(url1, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            document: jsonStr
           });
           if(this.state.document == null){
            this.state.document = null;
          }
       });



        const url = Config.url+'api/gadget';
        fetch(url, { method: 'GET', headers })
        .then(function(response) {
            return response.json();
        })
          .then(jsonStr => {
          this.setState({ 
            gadget: jsonStr ? jsonStr : null
           });
           if(this.state.gadget == null){

            this.state.gadget = null;
          }
       });

  
   console.log(this.state.document)

 }

  render() {
     const { gadget,document } = this.state;
    return ( 

<header>
<Lines></Lines>
{/* <CookieConsent
    location="bottom"
    buttonText="Agree!!"
    cookieName="myAwesomeCookieName2"
    style={{ background: "#2B373B" }}
    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
    expires={150}
>
We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it Find out more.
.{" "}
    <a href="/Cookies" style={{ fontSize: "10px" }}>
    Find out more
    </a>
</CookieConsent> */}
<section class="navigation">
    <div class="nav-container ">
      <div class="brand">
        <a href={Config.siteurl}>
          <img src="../images/logo.png" class="img-fluid"/>
        </a>
      </div>
      {/* <div class="call_li call_mob noprint">
      <small>call us</small>
        <a class="tellink" href={"tel:03333 449 559"}><h4>03333 449 559</h4></a>
      </div> */}
      <nav className="noprint">
        <div class="nav-mobile"><a id="nav-toggle" href="#"><span></span></a></div>
        <ul class="nav-list">
          <li>
            <a href="/Aboutus"  target="_blank">About</a>
          </li>
          <li>
            <a href="/Gadgets"  target="_blank">Gadgets</a>
            
          </li>
          <li>
            <a href="/Contact" target="_blank">Contact Us</a>
          </li>
         
          <li class="call_li contact_num">
            {/* <small>call us</small> */}
            <a class="tellink" href={"tel:03333 449 559"}><h4>03333 449 559</h4></a>
          </li>
        </ul>
      </nav>
    </div>
</section>
</header>
  );
}
}
export default Header;