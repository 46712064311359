import React, { Component } from "react";
import Config from "../layout/Config";
import { Lines } from "react-preloaders";

class Sidebanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gadget: [],
      document: ""
    };
    this.gagdetPage = this.gagdetPage.bind(this);
  }

  gagdetPage(event) {
    window.location = "/Gadgets/" + event;
  }

  componentDidMount() {
    const headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Content-Type", "application/json");

    const url1 = Config.url + "api/document";

    fetch(url1, { method: "GET", headers })
      .then(function(response) {
        return response.json();
      })
      .then(jsonStr => {
        this.setState({
          document: jsonStr
        });
        if (this.state.document == null) {
          this.state.document = null;
        }
      });

    console.log(this.state.document);
  }

  render() {
    const { gadget, document } = this.state;
    return (
      <div class="hide-sm side_bar noprint">
        <div class="pglftcnt">
          <p
            dangerouslySetInnerHTML={{
              __html: this.state.document.offer_content
            }}
          ></p>
        </div>

        <img
          src={Config.url + this.state.document.offer_image}
          class="img-fluid"
        />
      </div>
    );
  }
}
export default Sidebanner;
