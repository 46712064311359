import React, {Component} from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import moment from 'moment';
import Sidebanner from '../layout/Sidebanner';
import MetaTags from 'react-meta-tags';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import SimpleReactValidator from "simple-react-validator";

class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentDetails: [],
            api_response: '',
            password_err: '',
            currentDate:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator();

    }
    handleChange(event) {
        const state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
        this.setState({
          password1:''
        });
    }
    componentDidMount() {
        if (localStorage.getItem('glength') <= 0 && localStorage.getItem('quote_id') == null) {
            window.location = "/";
        }
        const headers = new Headers();
        headers.set('Accept', 'application/json');
        // headers.set('Content-Type', 'multipart/form-data');
        const url =
            Config.url + "api/gadget-list/" + localStorage.getItem("quote_id");
            fetch(url, { method: "GET", headers })
            .then(function(response) {
                return response.json();
            })
            .then(jsonStr => {
                this.setState({
                gadget_list: jsonStr
                });
                const glength = this.state.gadget_list.length;
                console.log(jsonStr);

                //mail

                    const data = {
                        reference_id: localStorage.getItem('reference_no'),
                        fname: localStorage.getItem('hacfname'),
                        lname: localStorage.getItem('haclname'),
                        mail: localStorage.getItem('hacemail'),
                        postcode: localStorage.getItem('hacpost'),
                        mobile: localStorage.getItem('hactele'),
                        address: localStorage.getItem('hacaddress'),
                        gadget:this.state.gadget_list,
                        first_payment_date: localStorage.getItem('first_payment_date'),
                        payment_method: localStorage.getItem('payment_method'),
                        payment_date: localStorage.getItem('payment_date'),
                        amount: localStorage.getItem('amount'),
                        startdateofpolicy: localStorage.getItem('startdateofpolicy')

                    }
                    const crmstep2 = Config.crm+'processstep6';
                    const options1 = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers
                    };
                    fetch(crmstep2, options1)
                    .then(function(response) {
                        return response.json();                         
                    })
                    .then(jsonStr => {
                        this.setState({ 
                        api_response: jsonStr
                        })
                        // localStorage.clear();
                        // window.location = "/quotes/Thankyoupage";
                    }, (error) => {
                        this.setState({error});
                    })


            });

    }
    paymentDate = () =>{
        let getDate = moment().format('DD/MM/YYYY');
        let payment_date = localStorage.getItem('first_payment_date');
        (getDate === payment_date) ? this.setState({currentDate :true}) : this.setState({currentDate :false})
    }
    passwordsett = () => {

        var password = this.state.password;
        console.log(password);
        if (this.validator.allValid()) {
            // this.setState({password_err: ''});
            const headers = new Headers();
            headers.set("Accept", "application/json");
            const data = {
                reference_id: localStorage.getItem('reference_no'),
                password : this.state.password
            }
            const crmstep2 = Config.crm+'processstep5';
            const options1 = {
                method: 'POST',
                body: JSON.stringify(data),
                headers
            };
            fetch(crmstep2, options1)
            .then(function(response) {
                return response.json();                         
            })
            .then(jsonStr => {
                this.setState({ 
                api_response: jsonStr
                })
                localStorage.clear();
                window.location = "/quotes/Thankyoupage";
            }, (error) => {
                this.setState({error});
            })

        }else{
            this.validator.showMessages();
            // rerender to show messages for the first time you can use the autoForceUpdate
            // option to do this automatically`
            this.forceUpdate();
        }
        
        
    
    }
    render() {

        const { gadget_list ,currentDate} = this.state;

        return (
            <div>
                <Header/>
                <MetaTags>
            <title>Gadgets Quote</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags>
                <section id="pg-produc">
                    <div class="container">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" id="GFG">
                            <div class="gen-your-quote">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <h3>Generate your Quote</h3>
                                        <p>With just a few simple steps you will receive a personalised quote</p>
                                    </div>
                                </div>
                                <div class="pg-details-step">
                                    <div class="row justify-content-lg-around">
                                        <div class="col-md-12 col-lg-2 pd-steps active">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Your Details
                                            <i class="fas fa-angle-right"></i>
                                        </div>
                                        <div class="col-md-12 col-lg-2 pd-steps active">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Product Details
                                            <i class="fas fa-angle-right"></i>
                                        </div>
                                        <div class="col-md-12 col-lg-2 pd-steps active mbactive">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Product Review 
                                            <i class="fas fa-angle-right"></i>
                                        </div>
                                        <div class="col-md-12 col-lg-2 active pd-steps">
                                            <span class="prvstep">
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            Payment Methods
                                        </div>
                                        <div class="col-md-12 col-lg-2 pd-steps active">
                                            <span class="activespan">
                                            5</span> Policy Summary
                                        </div>
                                    </div>
                                </div>
                                <div class="sucess-sec text-center">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <i class="fa fa-check sucess-ico mt-5" aria-hidden="true"></i>
                
                                            <h2 class="pt-3">Your Annual payment of £{localStorage.getItem('amount')} is collected . </h2>
                                            
                                            <h5 class="pt-3 pay-succ">
                                                Set Your Password
                                            </h5>
                                           
                                            <p class="pt-3">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <TextField label="Password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={this.handleChange}
                                                        className="form_control"
                                                        type="password"
                                                        variant="outlined"  />
                                                        {this.validator.message(
                                                            "Password",
                                                            this.state.password,
                                                            "required"
                                                            )}  
                                                    </div>
                                                    {/* <div class="md-form">
                                                        <input
                                                            type="password"
                                                            value={this.state.password}
                                                            name="password"
                                                            onChange={this.handleChange}
                                                            autocomplete="off"
                                                            placeholder="Password"
                                                            className="form-control bdinput bdinputnew"
                                                        />
                                                        <span class="fm_er" id="password_err">{this.state.password_err}</span>
                                                        
                                                    </div> */}
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="md-form">
                                                        <button class="mt-5 payment-btn w-30" onClick={this.passwordsett}>Submit</button>
                                                    </div>
                                                </div>
                                                {/* <button
                                            className="mt-5 nxt_btn noprint printbtn"
                                            type="button"
                                            onClick={() => this.print()}
                                            value="print"
                                            >Print
                                            </button> */}
                                            </p>

                                            {/* <a href={Config.siteurl}>
                                                <button class="bt-5">Back to home</button>
                                            </a> */}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="table_wrap">
                                                <table class="ct_table">
                                                    <tbody>
                                                        <tr>
                                                        <td>Full Name</td>
                                                        <td>
                                                            {localStorage.getItem('hacfname')} &nbsp;
                                                            {localStorage.getItem('haclname')}
                                                        </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Postcode</td>
                                                        <td> {localStorage.getItem('hacpost')} </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Address</td>
                                                        <td> {localStorage.getItem('hacaddress')} </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Phone Number</td>
                                                        <td> {localStorage.getItem('hacpphone')} </td>
                                                        </tr>
                                                        {/* <tr><td>Date Of Birth</td><td> {moment(this.state.dob).format('DD/MM/YYYY')}  </td></tr> */}
                                                        <tr>
                                                        <td>Email</td>
                                                        <td> {localStorage.getItem('hacemail')} </td>
                                                        </tr>
                                                        <tr>
                                                        <td>Your Premium</td>
                                                        <td>  £{localStorage.getItem('amount')} </td>
                                                        </tr>
                                                        <tr>
                                                            
                                                        </tr>
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="table-responsive">
                                                <table class="ct_table table_details">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Device Type</th>
                                                        <th scope="col">Make</th>
                                                        <th scope="col">Model</th>
                                                        <th scope="col">IMEI/Serial Number</th>
                                                        <th scope="col">Purchase Price</th>
                                                        <th scope="col">New or Refurbished</th>
                                                        <th scope="col">Age</th>
                                                        <th scope="col">Retailer</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {gadget_list && gadget_list.map(
                                                        function(gadget_list, key) {
                                                        return (
                                                            <tr>
                                                                <td> {gadget_list.title}</td>
                                                                <td> {gadget_list.makes}</td>
                                                                <td> {gadget_list.models}</td>
                                                                <td> {gadget_list.imei}</td>
                                                                <td> £{parseFloat(gadget_list.range_id).toFixed(2)}</td>
                                                                <td>
                                                                    {gadget_list.new_refurb == "1"
                                                                    ? "New"
                                                                    : "Refurbished"}
                                                                </td>
                                                                <td> {gadget_list.age}&nbsp;&nbsp;Months</td>
                                                                <td> {gadget_list.retailer}</td>
                                                            </tr>
                                                        );
                                                        }.bind(this)
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}
export default Success;
