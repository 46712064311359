import React, {Component} from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Config from '../layout/Config';
import {Helmet} from "react-helmet";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_insurance: [],
            gadget_page: [],
            document: "",
            contact_us: '',
            status: '',
            seo: '',
            seo2: ''
        };
        this.handleChange = this
            .handleChange
            .bind(this);
        this.showContent = this
            .showContent
            .bind(this);
    }

    showContent = () => {
        this.setState({status: false});
    }

    handleChange(event) {

        const state = this.state
        state[event.target.name] = event
            .target
            .value
            this
            .setState(state);

        this.setState({name1: '', email1: '', message1: ''})
    }
    makeContact = () => {

        var name = this.state.name;
        var email = this.state.email;
        var message = this.state.message;

        if (name == '' || name == null || name == undefined) {
            this.setState({name1: 'alert'});
        } else if (email == '' || email == null || email == undefined) {
            this.setState({email1: 'alert'});
        } else if (message == '' || message == null || message == undefined) {
            this.setState({message1: 'alert'});
        } else {
            var apiUrl = Config.url + 'api/contact_us';
            const data = {
                quote_id: localStorage.getItem('quote_id'),
                name: this.state.name,
                email: this.state.email,
                message: this.state.message
            }
            const headers = new Headers();
            headers.set('Accept', 'application/json');
            headers.set('Content-Type', 'multipart/form-data');
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
                headers
            };
            fetch(apiUrl, options, {mode: 'no-cors'})
                .then(function (response) {
                    return response.json();
                })
                .then(jsonStr => {
                    this.setState({contact_us: jsonStr})

                    console.log(this.state.contact_us);
                });
            if (this.state.contact_us != null) {

                this.setState({status: true});

                setTimeout(() => this.showContent(), 4000);
            }

        }
    }
    componentDidMount() {

        const headers = new Headers();
        headers.set('Accept', 'application/json');
        headers.set('Content-Type', 'multipart/form-data');

        const url1 = Config.url + 'api/document';

        fetch(url1, {
            method: 'GET',
            headers
        })
            .then(function (response) {
                return response.json();
            })
            .then(jsonStr => {
                this.setState({document: jsonStr});
                if (this.state.document == null) {
                    this.state.document = null;
                }
            });
        console.log(this.state.document)
        const urls7 = Config.url + 'api/seo';
        fetch(urls7, {
            method: 'GET',
            headers
        })
            .then(function (response) {
                return response.json();
            })
            .then(jsonStr => {
                this.setState({seo: jsonStr['contact'][0].title,
                    seo2: jsonStr['contact'][0].description
                });

            });
    }
    render() {
        console.log(this.state.document.ipt_percentage)
        return (
            <div>
                <Header/>
                <Helmet>

                    <meta charSet="utf-8"/>
                    <title>{this.state.seo}</title>
                    <meta name="description" content={this.state.seo2}/>
                    <link
                        rel="canonical"
                        href={'www.covermygadget.com' + this.props.location.pathname}/>
                </Helmet>
                <section id="contact_sec">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="contact_left">
                                <h1>Contact Us</h1>
                                <p>We’re here to help and answer any questions you might have</p>
                                <form>
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            className={this.state.name1 == "alert"
                                                ? "valid-alert form-control contactinput"
                                                : "form-control"}
                                            id="name"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            className={this.state.email1 == "alert"
                                                ? "valid-alert form-control contactinput"
                                                : "form-control"}
                                            id="email"/>
                                    </div>
                                    <div class="form-group">
                                        <label for="message">Message</label>
                                        <input
                                            type="text"
                                            name="message"
                                            value={this.state.message}
                                            onChange={this.handleChange}
                                            className={this.state.message1 == "alert"
                                                ? "valid-alert form-control contactinput"
                                                : "form-control"}
                                            id="message"/>
                                    </div>
                                    <button type="button" onClick={this.makeContact}>Submit</button>
                                </form>

                                {
                                    this.state.status == true
                                        ? <div class="sucess-sec failure text-center">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h2 class="pt-3">
                                                            Thank You. We will Contact Back Soon
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        : <div></div>
                                }

                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 margin-dl">
                            <div class="contact_right">
                                <table class="contact_tb">
                                    <tr>
                                        <td>
                                            <img src="images/location.png" class="img-fluid"/>
                                        </td>
                                        <td>
                                            <h4>ADDRESS</h4>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: this.state.document.address
                                                }}></p>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src="images/phone.png" class="img-fluid"/>
                                        </td>
                                        <td>
                                            <h4>PHONE</h4>
                                            <p>{this.state.document.contact_no}
                                            </p>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <img src="images/mail.png" class="img-fluid"/>
                                        </td>
                                        <td>
                                            <h4>EMAIL</h4>
                                            <p>{this.state.document.email}
                                            </p>

                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td><img src="images/timing.png" class="img-fluid"/></td>
                                        <td class="timing_td">
                                            <p class="text-bold">MONDAY/FRIDAY
                                                <br/>
                                                SATURDAY
                                            </p>
                                        </td>
                                        <td class="timing_td">
                                            <p>10:00AM/7:00PM
                                                <br/>
                                                10:00AM/4:00PM
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}
export default Contact;
