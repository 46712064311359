import React, { Component } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Mfooter';
import Config from '../layout/Config';
import {Helmet} from "react-helmet";
class Product extends Component {
      constructor(props) {
    super(props);
    this.state = {
      phone_insurance:[],
      gadget_page:[],
      document:"",
      tab : 1,
      
    };
  }
 
 componentDidMount() {

 
  const headers = new Headers();
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'multipart/form-data');
  
  const url = Config.url+'api/gadget_page/' + this.props.match.params.id;
  fetch(url, { method: 'GET', headers })
  .then(function(response) {
      return response.json();
  })
    .then(jsonStr => {
    this.setState({ 
      gadget_page: jsonStr
     });
     if(this.state.gadget_page == null){
      this.state.gadget_page = null;
    }
 });

const url1 = Config.url+'api/document';

  fetch(url1, { method: 'GET', headers })
  .then(function(response) {
      return response.json();
  })
    .then(jsonStr => {
    this.setState({ 
      document: jsonStr
     });
     if(this.state.document == null){
      this.state.document = null;
    }
 });
 console.log(this.state.document)

 }
 changetab=(id)=>{
  this.setState({ 
    tab: id
   });
 }

render() {
  console.log(this.state.gadget_page);   


  const styles = {
    imag: {
        backgroundImage: `url(${Config.url + this.state.gadget_page.cover_image})`
    },
    imag1: {
        backgroundImage: `url(${Config.url + this.state.gadget_page.side_image})`
    },
    imag2: {
        backgroundImage: `url(${Config.url + this.state.gadget_page.quote_image})`
    },
  }
  return (
 <div>
    <Header />
    <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.gadget_page.title}</title>
                <meta name="description" content={this.state.gadget_page.meta_description} />
                <link rel="canonical" href={'www.covermygadget.com' + this.props.location.pathname} />
            </Helmet>
  <div class="prd-page">
    <div class="prd-cvr" style={styles.imag}>
        <div class="container">
            <div class="row">
                <div class="col-5 col-sm-6 col-md-6 col-lg-6">
                    
                </div>
                <div class="col-7 col-sm-6 col-md-6 col-lg-6 half-div">
                    <h1>{this.state.gadget_page.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.quote_content}}></p>

                   
                    <a href="/quotes/Details"><button>Get instant Quote</button></a>
                </div>
            </div>
        </div>
    </div>






    
    <div class="prt-prd">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="text-center" dangerouslySetInnerHTML={{__html: this.state.gadget_page.protect}}>
              {/* <h2>Protect what's important to you </h2>
              <p>Insure your iPhone today for full protection.</p> */}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div class="prt-left text-right bounceInLeft animatable">
                <h5>What is covered?</h5>
              <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.covered}}></p>

                {/* <p>Repair or replacement<span><img src="../images/icon-1.png"/></span></p>
                <p>Accidental loss<span><img src="../images/icon-1.png"/></span></p>
                <p>Accidental damage<span><img src="../images/icon-1.png"/></span></p>
                <p>Accidental water/liquid damage<span><img src="../images/icon-1.png"/></span></p>
                <p>Breakdown<span><img src="../images/icon-1.png"/></span></p> */}
              </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <div class="prt-center">
                <img src={Config.url + this.state.gadget_page.quote_image} class="img-fluid"/>
              </div>
          </div>
          <div class="col-sm-6 tab-mb">
          
            <ul class="nav nav-tabs" role="tablist">
            {this.state.tab ==1 &&
              <li class="nav-item">
                <a class="nav-link active" ><h5>What is covered?</h5></a>
              </li>
             } 
             {this.state.tab ==2 &&
              <li class="nav-item" onClick={() => this.changetab(1)}>
              <a class="nav-link "  ><h5>What is covered?</h5></a>
            </li>
             }
               {this.state.tab ==2 &&
              <li class="nav-item" >
                <a class="nav-link active"  ><h5>What is not covered?</h5></a>
              </li>
             }
             {this.state.tab ==1 &&
                  <li class="nav-item" onClick={() => this.changetab(2)}>
                <a class="nav-link" ><h5>What is not covered?</h5></a>
              </li>

             } 
            </ul>

            
            <div class="tab-content">
            {this.state.tab ==1 &&
              <div  class="container tab-pane active"><br/>
              <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.mob_covered}}></p>
                
              </div>
             }
             {this.state.tab ==2 &&
              <div  class="container tab-pane  active"><br/>
              <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.benefits}}></p>
                
              </div>
              }
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
              <div class="prt-right animatable bounceInRight">
                <h5>What is not covered?</h5>
                <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.benefits}}></p>
                {/* <p><span><img src="../images/icon-1.png"/>Fast repairs</span></p>
                <p><span><img src="../images/icon-1.png"/>Repair or replacement</span></p>  
                <p><span><img src="../images/icon-1.png"/>Breakdown repairs</span></p>
                <p><span><img src="../images/icon-1.png"/>Qualified engineers</span></p>
                <p><span><img src="../images/icon-1.png"/>Unauthorized use cover</span></p> */}
              </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
          <a href="/quotes/Details"><button>Get a Quote</button></a>
          </div>
          </div>
        </div>
      </div>
      <div class="prd-how-help">
        <div class="row">
          <div class="hidden-xs hidden-sm hidden-md col-lg-5 sp-bg" style={styles.imag1}>
            <img src="../images/shape.svg" class="img-fluid sprt-svg" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
            <div class="how-help-right">
              <h2>How we help you</h2>
               <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.description}}>
                     </p> 
              <div class="row">
                <div class="col-xs-6 col-sm-4">
                   <table>
                     <tr>
                       <td>
                        <img src="../images/1.png" />
                       </td>
                       <td>
                         “Help you in identifying the problem”
                       </td>
                     </tr>
                   </table>
                 
                </div>
                <div class="col-xs-6 col-sm-4">
                  <table>
                    <tr>
                      <td>
                       <img src="../images/2.png" />
                      </td>
                      <td>
                        “Diagnose and provide a solution to the issue”
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xs-6 col-sm-4">
                  <table>
                    <tr>
                      <td>
                       <img src="../images/3.png" />
                      </td>
                      <td>
                        “Get your equipment back to working as usual” 
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="prd-what">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div class="prd-what-left">
                  <img src={Config.url + this.state.gadget_page.covered_image} class="img-fluid" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <div class="prd-what-right">
                <h2>What are the benifits?</h2>
                {/* <h4>Main exclusions</h4> */}

                     <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.content}}></p>
                             
                
              </div>
            </div>
          </div>
          <div class="pdf-sec">
            <table>
            <tr class="text-center">
                <td> <span class="spanbd">Documents</span></td>
                <td><a href={Config.url + this.state.document.document}  target="_blank"><img src="../images/pdf.png" class="img-fluid"/><span class="brdr-r"> Gadget T&C's &nbsp;&nbsp;</span></a></td>
                <td><a href={Config.url + this.state.document.document_ipid}  target="_blank"><img src="../images/ipid.png" class="img-fluid"/><span>Gadget IPID</span></a></td>
              </tr>
            </table>
            </div>
        </div>
        <div class="abt-footer">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <dv class="abt_footer_in text-center">
                            <h2>{this.state.gadget_page.title}</h2>
                              <p dangerouslySetInnerHTML={{__html: this.state.gadget_page.insurance}}></p>
                             <center>
                             <a href="/quotes/Details"><button>Protect your Gadget now</button></a>
                            </center>
                        </dv>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>
 
<Footer />
</div>
);
}
}
export default Product;
