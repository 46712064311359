import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Config from '../layout/Config';


 class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_insurance:[],
      gadget: [],
      gadget_page:[],
      document:""
      
    };
    this.gagdetPage = this.gagdetPage.bind(this);
  }
  gagdetPage(event) {
    window.location = "/Gadgets/" + event;
 }
  componentDidMount() {

 
    const headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'multipart/form-data');

    const url = Config.url+'api/gadget';
    fetch(url, { method: 'GET', headers })
    .then(function(response) {
        return response.json();
    })
      .then(jsonStr => {
      this.setState({ 
        gadget: jsonStr ? jsonStr : null
       });
       if(this.state.gadget == null){

        this.state.gadget = null;
      }
   });
   
  const url1 = Config.url+'api/document';
  
    fetch(url1, { method: 'GET', headers })
    .then(function(response) {
        return response.json();
    })
      .then(jsonStr => {
      this.setState({ 
        document: jsonStr
       });
       if(this.state.document == null){
        this.state.document = null;
      }
   });
   console.log(this.state.document)
  
   }
  
  render() {
    const { gadget} = this.state;

    return ( 
    <footer>
        <div class="container">
            <div class="footer-sec">
                <div class="row">
                    <div class="col-md-3 col-sm-6">
                        <h4>Our Insurance Cover</h4>
                        <ul>
                            <li>
                                <a href="https://smart-cover.co.uk/home-emergency-insurance">
                           Home Emergency
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/appliance-insurance">
                           Appliance Insurance
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/gadget-insurance">
                           Gadget Insurance 
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/tv-insurance">
                           Television Insurance
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/satellite-insurance">
                           Satellite Insurance
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/home-insurance">
                           Home Insurance
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/kitchen-appliance-insurance">
                           Home Appliance Insurance
                           </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2 col-sm-6">
                        <h4>Help</h4>
                        <ul>
                            <li>
                                <a href="https://smart-cover.co.uk/contact-us/">
                           Contact Us
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/price-match">
                           Price Match Promise
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/refund-policy">
                           Refund Policy
                           </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2 col-sm-6">
                        <h4>Customer Services</h4>
                        <ul>
                            <li>
                                <a href="https://smart-cover.co.uk/customer-reviews">
                           Customer Reviews
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/key-benefits">
                           Key Benefits
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/complaints">
                           Complaints
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/feedback">
                           Feedback
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/about-us">
                           About Us
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/opt-out">
                           Opt-Out
                           </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-2 col-sm-6">
                        <h4>Site News</h4>
                        <ul>
                            <li>
                                <a href="https://smart-cover.co.uk/Blogs">
                           Latest Update
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/sponsors">
                           Sponsors
                           </a>
                            </li>
                            <li>
                                <a href="https://smart-cover.co.uk/newsletters">
                           Newsletters
                           </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h4>Payment Options</h4>
                        <img src="https://smart-cover.co.uk/wp-content/themes/Smart Cover/img/payment.png" class="img-fluid"/>
                        <br/>
                        <h4>
                            Follow Us
                        </h4>
                        <div class="footer-social-links py-3">
                            <div class="social-icon-wrap fb-wrap"><a href="https://www.facebook.com/smartcoverinsuranceuk/" class="social-icon" target="_blank"><i class="fab fa-facebook-f fb-color"></i></a></div>
                            <div class="social-icon-wrap tw-wrap"><a href="https://twitter.com/smartcoveruk " class="social-icon" target="_blank"><i class="fab fa-twitter tw-color"></i></a></div>
                            <div class="social-icon-wrap ln-wrap"><a href="https://www.linkedin.com/company/smart-cover-direct/" class="social-icon" target="_blank"><i class="fab fa-linkedin-in ln-color"></i></a></div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="col-12 text-center">
                        <p>
                        This website is intended for customers based in the UK and is therefore subject to the UK regulatory regime(s)

Smart-Cover Insurance Services, Smart Cover and Smart Cover 247 are all trading names of Smart-Cover Direct Limited which is an Appointed Representative of Asurit Limited who is authorised and regulated by the Financial Conduct Authority (FCA), for further information please visit the FCA’s register and search for Smart-Cover Direct Limited, FRN 600428. The Registered Address of Smart-Cover Direct Ltd is: Ashley Court, 32 Main Street, Ashley Leicestershire LE16 8HF and our principal Place of Business and Postal Address is: 1st Floor, 3-5 Rickmansworth Road, Watford, WD18 0GX. The Financial Ombudsman Service (FOS) is an agency for arbitrating on unresolved complaints between regulated firms and their clients. Full details of the FOS can be found on its website at www.financial-ombudsman.org.uk. In addition to this, the EU’s Online Dispute Resolution (ODR) platform may be found on their website at https://ec.europa.eu/consumers/odr (please note that consumers will no longer be able to use the Online Dispute Resolution (ODR) platform from 1 January 2021)
                        </p>
                    </div>
                    <div class="col-12 text-center">
                      <span>
                          <a href="https://smart-cover.co.uk/disclaimer" class="dt-br1" target="_blank"> 
                            Disclaimer
                          </a>
                          <a href="https://smart-cover.co.uk/privacy-policy"  class="dt-br1" target="_blank"> 
                            Privacy
                          </a>
                          <a href="https://smart-cover.co.uk/cookies" target="_blank">  
                            Cookies
                          </a>
                     </span>
                    </div>
                    <div class="col-12 text-center">
                        <p class="pt-5">© {new Date().getFullYear()} Smart Cover Insurance Services All Rights Reserved</p>
                    </div>
                </div>
            </div>
    </footer>
);
}
}
export default Footer;