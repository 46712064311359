import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Config from '../layout/Config';


 class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_insurance:[],
      gadget: [],
      gadget_page:[],
      document:""
      
    };
    this.gagdetPage = this.gagdetPage.bind(this);
  }
  gagdetPage(event) {
    window.location = "/Gadgets/" + event;
 }
  componentDidMount() {

 
    const headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'multipart/form-data');

    const url = Config.url+'api/gadget';
    fetch(url, { method: 'GET', headers })
    .then(function(response) {
        return response.json();
    })
      .then(jsonStr => {
      this.setState({ 
        gadget: jsonStr ? jsonStr : null
       });
       if(this.state.gadget == null){

        this.state.gadget = null;
      }
   });
   
  const url1 = Config.url+'api/document';
  
    fetch(url1, { method: 'GET', headers })
    .then(function(response) {
        return response.json();
    })
      .then(jsonStr => {
      this.setState({ 
        document: jsonStr
       });
       if(this.state.document == null){
        this.state.document = null;
      }
   });
   console.log(this.state.document)
  
   }
  
  render() {
    const { gadget} = this.state;

    return ( 
<div>
<footer class="mt-0">
<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <center>
          <img src="../images/footer-logo.png" class="img-fluid"/>
        </center>
        <div class="footer-nav">
            <nav class="navbar navbar-expand-sm bg-light">
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link" href="/Aboutus">About</a>
                  </li>
                  {/* <li class="nav-item">
                    <a class="nav-link" href="#">Gadgets</a>
                  </li> */}
                  <li class="nav-item">
                    <a class="nav-link" href="/Cookies">Cookies</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/Privacy-Policy">Privacy Policy</a>
                  </li>
                  {/* <li class="nav-item">
                    <a class="nav-link" href="#">Opt-Out</a>
                  </li> */}
                  <li class="nav-item">
                    <a class="nav-link" href="/Contact">Contact Us</a>
                  </li>
                </ul>
              </nav>
              <p dangerouslySetInnerHTML={{__html: this.state.document.footer}}></p>
        </div>
    </div>
  </div>
</div>
</footer>

</div>
);
}
}
export default Footer;